<template>
	<div class="animated fadeIn">
		<b-form novalidate class="main-info" @submit.prevent="onSubmit">
			<div class="main-info__body">
				<h1 class="main-info__body-title">
					Создание рейса
				</h1>
				<div>
					<h5 class="main-info__body-subtitle">
						Детали рейса
					</h5>
					<b-row>
						<b-col md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Код маршрута<span class="text-danger"
										>*</span
									>
								</p>
								<b-form-group class="form-group-el-select">
									<el-select
										v-model="form.playedRouteWay.value"
										class="form-control"
										name="responsible"
										placeholder=""
										clearable
										:filterable="true"
										:remote="true"
										reserve-keyword
										:remote-method="routeWaysShortlist"
										:loading="loading"
										@change="
											onRouteChange(
												form.playedRouteWay.value
											)
										"
									>
										<el-option
											v-for="item in form.playedRouteWay
												.options"
											:key="item.value"
											:label="item.text"
											:value="item.value"
										/>
									</el-select>
								</b-form-group>
							</div>
						</b-col>
						<!--b-col md="4">
              <div class="main-info__item">
                <p class="main-info__item-title">
                  Стоимость рейса
                </p>
                <b-form-group
                  id="usernameInputGroup1"
                  class="form-group-el-select"
                  description="Формат заполнения ячейки - 10000. Валюта - рубли."
                >
                  <b-form-input
                    id="route-price"
                    v-model="form.freightSumm"
                    type="number"
                    name="route-price"
                    min="1"
                    placeholder=""
                    autocomplete="route-price"
                    @wheel="$event.target.blur()"
                  />
                </b-form-group>
              </div>
            </b-col-->
						<b-col v-if="route.routeCode" md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Код рейса
								</p>
								<p class="main-info__item-desc">
									{{ route.routeCode }}
								</p>
							</div>
						</b-col>
						<b-col md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Грузопровод
								</p>
								<b-form-checkbox
									id="cargoFlow"
									v-model="form.cargoFlow"
									name="cargoFlow"
									@change="onCargoFlowChange(form.cargoFlow)"
								>
									Да
								</b-form-checkbox>
							</div>
						</b-col>
						<b-col md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Тип перевозки<span class="text-danger"
										>*</span
									>
								</p>
								<b-form-group class="form-group-el-select">
									<el-select
										v-model="
											form.routeTransportations.value
										"
										class="form-control"
										clearable
										:filterable="true"
										reserve-keyword
										:disabled="!form.playedRouteWay.value"
										:loading="loading"
									>
										<el-option
											v-for="item in form
												.routeTransportations.options"
											:key="item.value"
											:label="item.text"
											:value="item.value"
										/>
									</el-select>
								</b-form-group>
							</div>
						</b-col>
						<b-col v-if="route.typeId" md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Вид перевозки<span class="text-danger"
										>*</span
									>
								</p>
								<p class="main-info__item-desc">
									{{ route.type }}
								</p>
							</div>
						</b-col>
						<b-col v-if="route.fullDistance" md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Общее расстояние
								</p>
								<p class="main-info__item-desc">
									{{ route.fullDistance }} км.
								</p>
							</div>
						</b-col>
						<b-col v-if="!form.cargoFlow" md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Ускоренный
								</p>
								<b-form-group id="acceleratedInputGroup3">
									<b-form-checkbox
										id="accelerated"
										v-model="form.accelerated"
										name="accelerated"
										:disabled="
											(Boolean(form.driverOne.value) &&
												Boolean(
													form.driverTwo.value
												)) ||
												(route.auction &&
													(route.auction.status ===
														1 ||
														route.auction.status ===
															2 ||
														route.auction.status ===
															3 ||
														route.auction.status ===
															4))
										"
									>
										Да
									</b-form-checkbox>
								</b-form-group>
							</div>
						</b-col>
					</b-row>
					<h5 class="main-info__body-subtitle">
						Ответственные
					</h5>
					<b-row>
						<b-col md="3">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Группа ответственных<span
										class="text-danger"
										>*</span
									>
								</p>
								<b-form-group
									id="responsibleInputGroup4"
									class="form-group-el-select"
								>
									<el-select
										v-model="form.responsible.value"
										class="form-control"
										name="responsible"
										placeholder="Начните вводить название"
										clearable
										:filterable="true"
										:remote="true"
										reserve-keyword
										:remote-method="responsibleRemote"
										:loading="loading"
										:disabled="
											route.auction &&
												(route.auction.status === 1 ||
													route.auction.status ===
														2 ||
													route.auction.status ===
														3 ||
													route.auction.status === 4)
										"
										@change="
											onResponsibleChange(
												form.responsible.value
											)
										"
										@clear="responsibleRemote('')"
									>
										<el-option
											v-for="item in form.responsible
												.options"
											:key="item.value"
											:label="item.text"
											:value="item.value"
										/>
									</el-select>
								</b-form-group>
							</div>
						</b-col>
						<b-col v-if="data.responsible.agent" md="3">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Контактные данные
								</p>
								<p class="main-info__item-desc">
									{{ data.responsible.agent.fullname }}
								</p>
								<div v-if="data.responsible.agent.userPhones">
									<p
										v-if="
											data.responsible.agent.userPhones
												.length
										"
									>
										Телефоны
									</p>
									<ul
										v-if="
											data.responsible.agent.userPhones
												.length
										"
									>
										<li
											v-for="phone in data.responsible
												.agent.userPhones"
											:key="phone.id"
										>
											<span v-if="phone.name">
												<span v-if="phone.name"
													>{{ phone.name }}:</span
												>
											</span>
											<a
												:href="
													'tel:' +
														phone.countryCode.code +
														phone.areaCode +
														phone.localNumber +
														phone.additionalNumber
												"
											>
												{{
													phone.countryCode.code +
														phone.areaCode +
														phone.localNumber
												}}
												<span
													v-if="
														phone.additionalNumber
													"
												>
													{{ phone.additionalNumber }}
												</span>
											</a>
										</li>
									</ul>
								</div>
								<p v-if="data.responsible.agent.email">
									Email:
									<a
										:href="
											'mailto:' +
												data.responsible.agent.email
										"
										>{{ data.responsible.agent.email }}</a
									>
								</p>
							</div>
						</b-col>
						<b-col
							v-if="
								!route.auction ||
									(route.auction &&
										route.auction.status === 5) ||
									route.auction.status === 6
							"
							md="3"
						>
							<div class="main-info__item">
								<p class="main-info__item-title">
									Перевозчик
								</p>
								<b-form-group
									id="contractorInputGroup5"
									class="form-group-el-select"
								>
									<el-select
										v-model="form.contractor.value"
										class="form-control"
										name="contractor"
										placeholder="Начните вводить название организации"
										clearable
										:filterable="true"
										:remote="true"
										reserve-keyword
										:remote-method="contractorRemote"
										:loading="loading"
										:disabled="!!route.auction"
										@change="
											onSelectContractorChange(
												form.contractor.value
											)
										"
										@clear="onSelectContractorClear()"
									>
										<el-option
											v-for="item in form.contractor
												.options"
											:key="item.value"
											:label="item.text"
											:value="item.value"
										/>
									</el-select>
								</b-form-group>
							</div>
						</b-col>
						<b-col md="3">
							<p class="main-info__item-title">
								Организация<span class="text-danger">*</span>
							</p>
							<b-form-group
								id="organizationsInputGroup"
								class="form-group-el-select"
								label-for="organizationSelect"
							>
								<el-select-clearable
									v-model="form.organizations.value"
									class="form-control"
									name="organizations"
									placeholder="Выберите организацию"
									clearable
									:filterable="true"
									:remote="true"
									reserve-keyword
									:remote-method="getOrganizationsList"
									:loading="loading"
								>
									<el-option
										v-for="item in form.organizations
											.options"
										:key="item.value"
										:label="item.text"
										:value="item.value"
									/>
									<b-form-invalid-feedback
										id="input1LiveFeedback2"
									>
										Это поле обязательно для заполнения
									</b-form-invalid-feedback>
								</el-select-clearable>
							</b-form-group>
						</b-col>
					</b-row>
					<h5 v-if="!form.cargoFlow" class="main-info__body-subtitle">
						Водители
					</h5>
					<b-row v-if="!form.cargoFlow">
						<b-col md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Водитель 1
								</p>
								<b-form-group
									id="driverOneInputGroup6"
									class="form-group-el-select"
									:class="{
										errored: !(
											data.driverOne.activeAttorney &&
											data.driverOne.status === 1
										),
									}"
								>
									<el-select
										v-model="form.driverOne.value"
										class="form-control"
										name="driver-one"
										placeholder="Начните вводить фамилию, имя и/или отчество"
										clearable
										:filterable="true"
										:remote="true"
										reserve-keyword
										:remote-method="driverOneRemote"
										:loading="loading"
										:disabled="form.contractor.value === ''"
										@change="
											onDriverOneChange(
												form.driverOne.value
											)
										"
										@clear="driverOneRemote('')"
									>
										<el-option
											v-for="item in form.driverOne
												.options"
											:key="item.value"
											:label="item.text"
											:value="item.value"
											:class="{ errored: item.disabled }"
										/>
									</el-select>
									<small
										v-if="form.driverOne.value"
										class="main-info__item-desc-note"
									>
										<span
											v-if="
												!data.driverOne
													.activeAttorney &&
													data.driverOne.status !== 1
											"
										>
											Данный водитель не верифицирован и
											не имеет активной доверенности
										</span>
										<span
											v-else-if="
												data.driverOne.activeAttorney &&
													data.driverOne.status !== 1
											"
										>
											Данный водитель не верифицирован
										</span>
										<span
											v-else-if="
												!data.driverOne
													.activeAttorney &&
													data.driverOne.status === 1
											"
										>
											Данный водитель не имеет активной
											доверенности
										</span>
									</small>
									<!--div
                    v-if="form.driverOne.value && data.driverOne && !data.driverOne.inn"
                    class="main-info__item-desc-note text-danger"
                  >
                    <p>Пожалуйста, укажите ИНН водителя, для возможности использовать его в рейсе</p>
                    <driver-inn-edit
                      v-model="data.driverOne.inn"
                      :driver-id="form.driverOne.value"
                    />
                  </div-->
								</b-form-group>
								<div
									v-if="form.driverOne.value"
									class="main-info__item-info-card"
								>
									<p class="main-info__item-info-card-title">
										{{ data.driverOne.fio }}
									</p>
									<ul class="main-info__item-info-card-list">
										<li
											v-if="data.driverOne.inn"
											class="main-info__item-info-card-list-item"
										>
											ИНН: {{ data.driverOne.inn }}
										</li>
										<li
											v-if="
												data.driverOne.driverPhones
													.length
											"
											class="main-info__item-info-card-list-item"
										>
											Телефоны:
											<span
												v-for="(phone, index) in data
													.driverOne.driverPhones"
												:key="index"
											>
												<span v-if="phone.name"
													>{{ phone.name }}:</span
												>
												<a
													:href="
														'tel:' + phone.number
													"
													>{{ phone.number }}</a
												>;
											</span>
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Допуск:
											<span
												v-if="
													data.driverOne
														.driverAccessAuto
												"
												>Авто;
											</span>
											<span
												v-if="
													data.driverOne
														.driverAccessAuto ||
														data.driverOne
															.driverAccessCargoFlow
												"
												>Контейнер;
											</span>
											<span
												v-if="
													data.driverOne
														.driverAccessAuto ||
														data.driverOne
															.driverAccessContainer
												"
												>Грузопровод;
											</span>
										</li>
										<li
											v-if="
												data.driverOne.attorneys.length
											"
											class="main-info__item-info-card-list-item"
										>
											Доверенности:
											{{
												data.driverOne.attorneys.length
											}}
											<label
												class="main-info__item-info-card-switcher"
											>
												<input
													v-model="
														data.driverOne.switcher
													"
													type="checkbox"
													class="main-info__item-info-card-switcher-input"
												/>
												<span
													class="main-info__item-info-card-switcher-display"
												>
													<span
														v-if="
															data.driverOne
																.switcher
														"
														>Скрыть подробности<i
															class="main-info__item-info-card-switcher-display-icon fa fa-arrow-up"
													/></span>
													<span v-else
														>Показать подробности<i
															class="main-info__item-info-card-switcher-display-icon fa fa-arrow-down"
													/></span>
												</span>
											</label>
										</li>
										<ol
											v-if="
												data.driverOne.switcher &&
													data.driverOne.attorneys
														.length
											"
											class="main-info__item-info-card-list"
										>
											<li
												v-for="(attorney, index) in data
													.driverOne.attorneys"
												:key="index"
												class="main-info__item-info-card-list-item"
											>
												<a
													:href="
														`/contractors/id${form.contractor.value}/attorney/id${attorney.id}`
													"
													target="_blank"
													>Доверенность №{{
														attorney.number
													}}</a
												>
												<span
													v-if="attorney.status === 0"
													class="main-info__item-info-card-list-item-status bg-secondary"
												>
													На рассмотрении</span
												>
												<span
													v-else-if="
														attorney.status === 1
													"
													class="main-info__item-info-card-list-item-status bg-success"
												>
													Действует</span
												>
												<span
													v-else-if="
														attorney.status === 2
													"
													class="main-info__item-info-card-list-item-status bg-danger"
												>
													Аннулирована</span
												><br />
												Действует с
												{{ attorney.dateStart }} по
												{{ attorney.dateEnd }}<br />
												<span v-if="attorney.file">
													Загруженный файл:
													<a
														:href="
															'/files/' +
																attorney.file
																	.file.guid
														"
														target="_blank"
														@click.prevent="
															downloadFile(
																attorney.file
																	.file
															)
														"
														>{{
															attorney.file.file
																.originalName
														}}</a
													>
												</span>
											</li>
										</ol>
									</ul>
								</div>
							</div>
						</b-col>
						<b-col md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Водитель 2
								</p>
								<b-form-group
									id="driverTwoInputGroup6"
									class="form-group-el-select"
									:class="{
										errored: !(
											data.driverTwo.activeAttorney &&
											data.driverTwo.status === 1
										),
									}"
								>
									<el-select
										v-model="form.driverTwo.value"
										class="form-control"
										name="driver-two"
										placeholder="Начните вводить фамилию, имя и/или отчество"
										clearable
										:filterable="true"
										:remote="true"
										reserve-keyword
										:remote-method="driverTwoRemote"
										:loading="loading"
										:disabled="
											form.contractor.value === '' ||
												!form.accelerated
										"
										@change="
											onDriverTwoChange(
												form.driverTwo.value
											)
										"
										@clear="driverTwoRemote('')"
									>
										<el-option
											v-for="item in form.driverTwo
												.options"
											:key="item.value"
											:label="item.text"
											:value="item.value"
											:class="{ errored: item.disabled }"
										/>
									</el-select>
									<small
										v-if="form.driverTwo.value"
										class="main-info__item-desc-note"
									>
										<span
											v-if="
												!data.driverTwo
													.activeAttorney &&
													data.driverTwo.status !== 1
											"
										>
											Данный водитель не верифицирован и
											не имеет активной доверенности
										</span>
										<span
											v-else-if="
												data.driverTwo.activeAttorney &&
													data.driverTwo.status !== 1
											"
										>
											Данный водитель не верифицирован
										</span>
										<span
											v-else-if="
												!data.driverTwo
													.activeAttorney &&
													data.driverTwo.status === 1
											"
										>
											Данный водитель не имеет активной
											доверенности
										</span>
									</small>
									<!--div
                    v-if="form.driverTwo.value && data.driverTwo && !data.driverTwo.inn"
                    class="main-info__item-desc-note text-danger"
                  >
                    <p>Пожалуйста, укажите ИНН водителя, для возможности использовать его в рейсе</p>
                    <driver-inn-edit
                      v-model="data.driverTwo.inn"
                      :driver-id="form.driverTwo.value"
                    />
                  </div-->
								</b-form-group>
								<div
									v-if="form.driverTwo.value"
									class="main-info__item-info-card"
								>
									<p class="main-info__item-info-card-title">
										{{ data.driverTwo.fio }}
									</p>
									<ul class="main-info__item-info-card-list">
										<li
											v-if="data.driverTwo.inn"
											class="main-info__item-info-card-list-item"
										>
											ИНН: {{ data.driverTwo.inn }}
										</li>
										<li
											v-if="
												data.driverTwo.driverPhones
													.length
											"
											class="main-info__item-info-card-list-item"
										>
											Телефоны:
											<span
												v-for="(phone, index) in data
													.driverTwo.driverPhones"
												:key="index"
											>
												<span v-if="phone.name"
													>{{ phone.name }}:</span
												>
												<a
													:href="
														'tel:' + phone.number
													"
													>{{ phone.number }}</a
												>;
											</span>
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Допуск:
											<span
												v-if="
													data.driverTwo
														.driverAccessAuto
												"
												>Авто;
											</span>
											<span
												v-if="
													data.driverTwo
														.driverAccessAuto ||
														data.driverTwo
															.driverAccessCargoFlow
												"
												>Контейнер;
											</span>
											<span
												v-if="
													data.driverTwo
														.driverAccessAuto ||
														data.driverTwo
															.driverAccessContainer
												"
												>Грузопровод;
											</span>
										</li>
										<li
											v-if="
												data.driverTwo.attorneys.length
											"
											class="main-info__item-info-card-list-item"
										>
											Доверенности:
											{{
												data.driverTwo.attorneys.length
											}}
											<label
												class="main-info__item-info-card-switcher"
											>
												<input
													v-model="
														data.driverTwo.switcher
													"
													type="checkbox"
													class="main-info__item-info-card-switcher-input"
												/>
												<span
													class="main-info__item-info-card-switcher-display"
												>
													<span
														v-if="
															data.driverTwo
																.switcher
														"
														>Скрыть подробности<i
															class="main-info__item-info-card-switcher-display-icon fa fa-arrow-up"
													/></span>
													<span v-else
														>Показать подробности<i
															class="main-info__item-info-card-switcher-display-icon fa fa-arrow-down"
													/></span>
												</span>
											</label>
										</li>
										<ol
											v-if="
												data.driverTwo.switcher &&
													data.driverTwo.attorneys
														.length
											"
											class="main-info__item-info-card-list"
										>
											<li
												v-for="(attorney, index) in data
													.driverTwo.attorneys"
												:key="index"
												class="main-info__item-info-card-list-item"
											>
												<a
													:href="
														`/contractors/id${form.contractor.value}/attorney/id${attorney.id}`
													"
													target="_blank"
													>Доверенность №{{
														attorney.number
													}}</a
												>
												<span
													v-if="attorney.status === 0"
													class="main-info__item-info-card-list-item-status bg-secondary"
												>
													На рассмотрении</span
												>
												<span
													v-else-if="
														attorney.status === 1
													"
													class="main-info__item-info-card-list-item-status bg-success"
												>
													Действует</span
												>
												<span
													v-else-if="
														attorney.status === 2
													"
													class="main-info__item-info-card-list-item-status bg-danger"
												>
													Аннулирована</span
												><br />
												Действует с
												{{ attorney.dateStart }} по
												{{ attorney.dateEnd }}<br />
												<span v-if="attorney.file">
													Загруженный файл:
													<a
														:href="
															'/files/' +
																attorney.file
																	.file.guid
														"
														target="_blank"
														@click.prevent="
															downloadFile(
																attorney.file
																	.file
															)
														"
														>{{
															attorney.file.file
																.originalName
														}}</a
													>
												</span>
											</li>
										</ol>
									</ul>
								</div>
							</div>
						</b-col>
					</b-row>
					<h5 class="main-info__body-subtitle">
						Транспорт
					</h5>
					<b-row>
						<b-col v-if="!form.cargoFlow" md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Автомобиль
								</p>
								<b-form-group
									id="carInputGroup7"
									class="form-group-el-select"
									:class="{
										errored: data.car.blockedSecurity,
									}"
								>
									<el-select
										v-model="form.car.value"
										class="form-control"
										name="car"
										placeholder="Начните вводить ГРН автомобиля"
										clearable
										:filterable="true"
										:remote="true"
										reserve-keyword
										:remote-method="carRemote"
										:loading="loading"
										:disabled="form.contractor.value === ''"
										@change="onCarChange(form.car.value)"
										@clear="carRemote('')"
									>
										<el-option
											v-for="item in form.car.options"
											:key="item.value"
											:label="item.text"
											:value="item.value"
											:class="{ errored: item.errored }"
										/>
									</el-select>
									<small
										v-if="data.car.blockedSecurity"
										class="main-info__item-desc-note"
										>Данное ТС не допущено службой
										безопасности</small
									>
									<small
										v-if="data.wrongTS"
										class="main-info__item-desc-note"
										>Указанный состав ТС не соответствует
										требованиям выигранного аукциона</small
									>
								</b-form-group>
								<div
									v-if="form.car.value"
									class="main-info__item-info-card"
								>
									<p class="main-info__item-info-card-title">
										{{ data.car.name }},
										{{ data.car.registrationNumber }}
									</p>
									<ul
										v-if="data.car.type === 'Тягач'"
										class="main-info__item-info-card-list"
									>
										<li
											class="main-info__item-info-card-list-item"
										>
											Тип ТС: {{ data.car.type }}
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Грузоподъемность:
											{{ data.car.loadCapacity }}
											т
										</li>
									</ul>
									<ul
										v-else
										class="main-info__item-info-card-list"
									>
										<li
											class="main-info__item-info-card-list-item"
										>
											Тип ТС: {{ data.car.type }}
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Грузоподъемность:
											{{ data.car.loadCapacity }}
											т
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Контейнеровоз:
											<span
												v-if="
													data.car
														.containerTransportVehicle
												"
												>Да</span
											>
											<span v-else>Нет</span>
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Высота кузова:
											{{ data.car.bodyHeight }}
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Ширина кузова:
											{{ data.car.bodyWidth }}
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Глубина кузова:
											{{ data.car.bodyDepth }}
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Объем кузова:
											{{ data.car.bodyVolume }}
										</li>
										<li
											v-if="data.car.bodyType"
											class="main-info__item-info-card-list-item"
										>
											Тип кузова: {{ data.car.bodyType }}
										</li>
									</ul>
								</div>
							</div>
							<div
								v-if="
									form.car.value &&
										data.car.containerTransportVehicle
								"
								class="main-info__item"
							>
								<p class="main-info__item-title">
									Контейнер автомобиля
								</p>
								<b-form-group class="form-group-el-select">
									<el-select-clearable
										id="car-container"
										v-model="form.carContainer.value"
										class="form-control"
										name="car-container"
										placeholder="Начните вводить номер контейнера"
										clearable
										:multiple="true"
										:multiple-limit="1"
										:filterable="true"
										:remote="true"
										reserve-keyword
										:remote-method="carContainerRemote"
										:loading="loading"
										:disabled="form.contractor.value === ''"
										@change="
											onCarContainerChange(
												form.carContainer.value
											)
										"
										@clear="carContainerRemote('')"
									>
										<el-option
											v-for="item in form.carContainer
												.options"
											:key="item.value"
											:label="item.text"
											:value="item.value"
										/>
									</el-select-clearable>
									<small
										v-if="data.wrongTS"
										class="main-info__item-desc-note"
										>Указанный состав ТС не соответствует
										требованиям выигранного аукциона</small
									>
								</b-form-group>
								<div v-if="form.carContainer.value.length">
									<div
										v-for="(container,
										index) in data.carContainer"
										:key="`car-container-${index}`"
										class="main-info__item-info-card"
									>
										<p
											class="main-info__item-info-card-title"
										>
											{{ container.name }},
											{{ container.number }}
										</p>
										<ul
											class="main-info__item-info-card-list"
										>
											<li
												class="main-info__item-info-card-list-item"
											>
												Высота: {{ container.height }}
											</li>
											<li
												class="main-info__item-info-card-list-item"
											>
												Ширина: {{ container.width }}
											</li>
											<li
												class="main-info__item-info-card-list-item"
											>
												Глубина: {{ container.depth }}
											</li>
											<li
												class="main-info__item-info-card-list-item"
											>
												Объем: {{ container.volume }}
											</li>
											<li
												class="main-info__item-info-card-list-item"
											>
												Грузоподъемность:
												{{ container.maximumPayload }}
											</li>
										</ul>
									</div>
								</div>
							</div>
						</b-col>
						<b-col md="4">
							<div class="main-info__item">
								<p class="main-info__item-title">
									Прицеп
								</p>
								<b-form-group
									id="trailerInputGroup9"
									class="form-group-el-select"
									:class="{
										errored: data.trailer.blockedSecurity,
									}"
								>
									<el-select
										v-model="form.trailer.value"
										class="form-control"
										name="trailer"
										placeholder="Начните вводить ГРН прицепа или полуприцепа"
										clearable
										:filterable="true"
										:remote="true"
										reserve-keyword
										:remote-method="trailerRemote"
										:loading="loading"
										:required="data.car.type === 'Тягач'"
										:disabled="form.contractor.value === ''"
										@change="
											onTrailerChange(form.trailer.value)
										"
										@clear="trailerRemote('')"
									>
										<el-option
											v-for="item in form.trailer.options"
											:key="item.value"
											:label="item.text"
											:value="item.value"
											:class="{ errored: item.errored }"
										/>
									</el-select>
									<small
										v-if="data.trailer.blockedSecurity"
										class="main-info__item-desc-note"
										>Данное ТС не допущено службой
										безопасности</small
									>
									<small
										v-if="data.wrongTS"
										class="main-info__item-desc-note"
										>Указанный состав ТС не соответствует
										требованиям выигранного аукциона</small
									>
								</b-form-group>
								<div
									v-if="form.trailer.value"
									class="main-info__item-info-card"
								>
									<p class="main-info__item-info-card-title">
										{{ data.trailer.name }},
										{{ data.trailer.registrationNumber }}
									</p>
									<ul class="main-info__item-info-card-list">
										<li
											class="main-info__item-info-card-list-item"
										>
											Тип ТС: {{ data.trailer.type }}
										</li>
										<li
											v-if="
												data.trailer
													.loadCapacity
											"
											class="main-info__item-info-card-list-item"
										>
											Грузоподъемность:
											{{
												data.trailer
													.loadCapacity
											}}
											т
										</li>
										<li
											class="main-info__item-info-card-list-item"
										>
											Контейнеровоз:
											<span
												v-if="
													data.trailer
														.containerTransportVehicle
												"
												>Да</span
											>
											<span v-else>Нет</span>
										</li>
										<li
											v-if="data.trailer.bodyHeight"
											class="main-info__item-info-card-list-item"
										>
											Высота кузова:
											{{ data.trailer.bodyHeight }}
										</li>
										<li
											v-if="data.trailer.bodyWidth"
											class="main-info__item-info-card-list-item"
										>
											Ширина кузова:
											{{ data.trailer.bodyWidth }}
										</li>
										<li
											v-if="data.trailer.bodyDepth"
											class="main-info__item-info-card-list-item"
										>
											Глубина кузова:
											{{ data.trailer.bodyDepth }}
										</li>
										<li
											v-if="data.trailer.bodyVolume"
											class="main-info__item-info-card-list-item"
										>
											Объем кузова:
											{{ data.trailer.bodyVolume }}
										</li>
										<li
											v-if="data.trailer.bodyType"
											class="main-info__item-info-card-list-item"
										>
											Тип кузова:
											{{ data.trailer.bodyType }}
										</li>
									</ul>
								</div>
							</div>
							<div
								v-if="
									!form.cargoFlow &&
										form.trailer.value &&
										data.trailer.containerTransportVehicle
								"
								class="main-info__item"
							>
								<div class="main-info__item">
									<p class="main-info__item-title">
										Контейнер прицепа
									</p>
									<b-form-group
										id="trailerContainerInputGroup10"
										class="form-group-el-select"
										label=""
										label-for="trailer-container"
									>
										<el-select-clearable
											id="trailer-container"
											v-model="
												form.trailerContainer.value
											"
											class="form-control"
											name="trailer-container"
											placeholder="Начните вводить номер контейнера"
											clearable
											:multiple="true"
											:multiple-limit="2"
											:filterable="true"
											:remote="true"
											reserve-keyword
											:remote-method="
												trailerContainerRemote
											"
											:loading="loading"
											:disabled="
												form.contractor.value === ''
											"
											@change="
												onTrailerContainerChange(
													form.trailerContainer.value
												)
											"
											@clear="trailerContainerRemote('')"
										>
											<el-option
												v-for="item in form
													.trailerContainer.options"
												:key="item.value"
												:label="item.text"
												:value="item.value"
											/>
										</el-select-clearable>
										<small
											v-if="data.wrongTS"
											class="main-info__item-desc-note"
											>Указанный состав ТС не
											соответствует требованиям
											выигранного аукциона</small
										>
									</b-form-group>
									<div
										v-if="
											form.trailerContainer.value.length
										"
									>
										<div
											v-for="(container,
											index) in data.trailerContainer"
											:key="`car-container-${index}`"
											class="main-info__item-info-card"
										>
											<p
												class="main-info__item-info-card-title"
											>
												{{ container.name }},
												{{ container.number }}
											</p>
											<ul
												class="main-info__item-info-card-list"
											>
												<li
													class="main-info__item-info-card-list-item"
												>
													Высота:
													{{ container.height }}
												</li>
												<li
													class="main-info__item-info-card-list-item"
												>
													Ширина:
													{{ container.width }}
												</li>
												<li
													class="main-info__item-info-card-list-item"
												>
													Глубина:
													{{ container.depth }}
												</li>
												<li
													class="main-info__item-info-card-list-item"
												>
													Объем:
													{{ container.volume }}
												</li>
												<li
													class="main-info__item-info-card-list-item"
												>
													Грузоподъемность:
													{{
														container.maximumPayload
													}}
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</b-col>
					</b-row>
					<div v-if="!form.cargoFlow && form.playedRouteWay.value">
						<h5 class="main-info__body-subtitle">
							Маршрут
						</h5>
						<b-row>
							<b-col md="6">
								<div class="main-info__item">
									<p class="main-info__item-title">
										Плановая дата и время прибытия в первую
										точку маршрута<span class="text-danger"
											>*</span
										>
									</p>
									<b-form-group
										id="firstPointArrivalDatetimeInputGroup2"
									>
										<el-date-picker-input
											id="first-point-arrival-datetime"
											v-model="
												form.firstPointArrivalDatetime
											"
											type="datetime"
											placeholder=""
											format="dd.MM.yyyy HH:mm"
											:picker-options="pickerOptions"
											:disabled="
												route.auction &&
													(route.auction.status ===
														1 ||
														route.auction.status ===
															2 ||
														route.auction.status ===
															3 ||
														route.auction.status ===
															4)
											"
										/>
										<span class="main-info__item-desc-note"
											>Дата и время указаны в соотвествии
											с часовым поясом первой точки
											маршрута</span
										>
									</b-form-group>
								</div>
							</b-col>
							<b-col md="6">
								<div class="main-info__item">
									<p class="main-info__item-title">
										Плановая дата и время начала погрузки в
										первой точке маршрута
									</p>
									<b-form-group
										id="firstPointLoadingDatetimeInputGroup3"
									>
										<el-date-picker-input
											id="first-point-loading-datetime"
											v-model="
												form.firstPointLoadingDatetime
											"
											type="datetime"
											placeholder=""
											text="Указывайте дату и время в соотвествии с часовым поясом первой точки маршрута"
											format="dd.MM.yyyy HH:mm"
											:picker-options="pickerOptions"
											:disabled="
												route.auction &&
													(route.auction.status ===
														1 ||
														route.auction.status ===
															2 ||
														route.auction.status ===
															3 ||
														route.auction.status ===
															4)
											"
										/>
										<span class="main-info__item-desc-note"
											>Дата и время указаны в соотвествии
											с часовым поясом первой точки
											маршрута</span
										>
									</b-form-group>
								</div>
							</b-col>
						</b-row>
						<div class="main-info__route-info">
							<div
								v-for="(point, index) in route.points"
								:key="`point-${index}`"
								class="main-info__route-info-item"
							>
								<p class="main-info__route-info-item-title">
									<i
										class="main-info__route-info-item-title-icon fa fa-map-marker fa-lg mr-2 text-danger"
									/>
									{{ point.department.name }}
								</p>
								<div class="main-info__route-info-item-body">
									<b-row>
										<b-col md="4">
											<div class="main-info__item">
												<p
													class="main-info__item-title"
												>
													Адрес
												</p>
												<p class="main-info__item-desc">
													{{
														point.department
															.mainWarehouse
															.address
													}}
												</p>
											</div>
										</b-col>
										<b-col md="4">
											<div
												v-if="index > 0"
												class="main-info__item"
											>
												<p
													class="main-info__item-title"
												>
													Ориентировочное время в пути
												</p>
												<p class="main-info__item-desc">
													{{ point.timeSummer }}
												</p>
												<small><strong>*</strong> Точное время доставки будет указано <br> в транспортной накладной после отправки.</small>
											</div>
										</b-col>
										<b-col md="4">
											<div class="main-info__item">
												<p
													class="main-info__item-title"
												>
													Расстояние
												</p>
												<p class="main-info__item-desc">
													{{ point.distance }}
												</p>
											</div>
										</b-col>
									</b-row>
								</div>
							</div>
						</div>
            <b-col xl="5" md="7" sm="8" v-if="form.comment.visible">
              <b-form-textarea
            class="mt-4"
            id="comment-area"
            name="comment-area"
            placeholder="Пожалуйста, введите комментарий (до 200 символов)"
            no-resize
            rows="5"
            maxlength = "200"
            v-model="form.comment.value"
            >
            </b-form-textarea>
            </b-col>
            <b-button
            type="button"
						variant="primary"
						class="ml-3 mt-4"
            @click="addComment()"
            v-if="form.comment.visible === false"
            >
              <i class="fa fa-plus" />&nbsp; &nbsp; Добавить комментарий
            </b-button>

			          <b-button
								type="button"
								variant="success"
								class="ml-3 mt-4"
								v-if="form.comment.visible && !updateButton"
                				:disabled="!form.comment.value"
								@click="saveComment()"
								>
								Сохранить
								</b-button>

								<b-button
								type="button"
								variant="success"
								class="ml-3 mt-4"
								v-if="form.comment.visible && updateButton"
                				:disabled="!form.comment.value"
								@click="updateComment()"
								>
								Обновить комментарий
								</b-button>

            <b-button
            type="button"
            variant="danger"
            class="ml-3 mt-4"
            v-if="form.comment.visible && !updateButton"
            @click="deleteComment()"
            >
              Удалить комментарий
            </b-button>
			<b-button
            type="button"
            variant="danger"
            class="ml-3 mt-4"
            v-if="form.comment.visible && updateButton"
            @click="destroyComment()"
            >
              Удалить комментарий
            </b-button>
					</div>
					<div
						v-else-if="form.cargoFlow && form.playedRouteWay.value"
					>
						<h5 class="main-info__body-subtitle">
							Маршрут
						</h5>
						<b-row>
							<b-col md="6">
								<div class="main-info__item">
									<p class="main-info__item-title">
										Плановая дата и время прибытия в первую
										точку маршрута<span class="text-danger"
											>*</span
										>
									</p>
									<b-form-group
										id="firstPointArrivalDatetimeInputGroup2"
									>
										<el-date-picker-input
											id="first-point-arrival-datetime"
											v-model="
												form.firstPointArrivalDatetime
											"
											type="datetime"
											placeholder=""
											format="dd.MM.yyyy HH:mm"
											:picker-options="pickerOptions"
											:disabled="
												route.auction &&
													(route.auction.status ===
														1 ||
														route.auction.status ===
															2 ||
														route.auction.status ===
															3 ||
														route.auction.status ===
															4)
											"
										/>
										<span class="main-info__item-desc-note"
											>Дата и время указаны в соотвествии
											с часовым поясом первой точки
											маршрута</span
										>
									</b-form-group>
								</div>
							</b-col>
							<b-col md="6">
								<div class="main-info__item">
									<p class="main-info__item-title">
										Плановая дата и время начала погрузки в
										первой точке маршрута
									</p>
									<b-form-group
										id="firstPointLoadingDatetimeInputGroup3"
									>
										<el-date-picker-input
											id="first-point-loading-datetime"
											v-model="
												form.firstPointLoadingDatetime
											"
											type="datetime"
											placeholder=""
											text="Указывайте дату и время в соотвествии с часовым поясом первой точки маршрута"
											format="dd.MM.yyyy HH:mm"
											:picker-options="pickerOptions"
											:disabled="
												route.auction &&
													(route.auction.status ===
														1 ||
														route.auction.status ===
															2 ||
														route.auction.status ===
															3 ||
														route.auction.status ===
															4)
											"
										/>
										<span class="main-info__item-desc-note"
											>Дата и время указаны в соотвествии
											с часовым поясом первой точки
											маршрута</span
										>
									</b-form-group>
								</div>
							</b-col>
						</b-row>
						<h5 class="main-info__body-subtitle">
							Грузопровод
						</h5>
						<div
							v-if="form.cargoPipelines.length > 0"
							class="main-info__cargo-pipelines-info"
						>
							<div
								v-for="(cargoPipeline,
								indexCargoPipeline) in form.cargoPipelines"
								:key="`point-${indexCargoPipeline}`"
								class="main-info__cargo-pipelines-info-item"
							>
								<div
									class="main-info__cargo-pipelines-info-item-header"
								>
									<p
										class="main-info__cargo-pipelines-info-item-title mr-2"
									>
										<i
											class="main-info__cargo-pipelines-info-item-title-icon fa fa-map-marker fa-lg mr-2 text-danger"
										/>
										{{
											cargoPipeline
												.cargoPipelinePlaceOfEvent.name
										}}
									</p>
									<div
										class="main-info__cargo-pipelines-info-item-header-buttons"
									>
										<b-button
											type="button"
											variant="primary"
											class="main-info__cargo-pipelines-info-item-header-button mr-2"
											@click="
												addNewEventToPlace(
													indexCargoPipeline
												)
											"
										>
											Добавить событие
										</b-button>
										<b-button
											v-b-tooltip.hover.top="'Удалить'"
											type="button"
											variant="danger"
											class="main-info__cargo-pipelines-info-item-header-button mr-2"
											:disabled="loading"
											@click="
												deletePlace(indexCargoPipeline)
											"
										>
											<i
												class="fa fa-trash fa-lg text-white"
											/>
										</b-button>
										<b-button
											v-if="indexCargoPipeline !== 0"
											v-b-tooltip.hover.top="'В начало'"
											type="button"
											variant="primary"
											class="main-info__cargo-pipelines-info-item-header-button mr-2"
											:disabled="loading"
											@click="
												changePlace(
													'start',
													indexCargoPipeline
												)
											"
										>
											<i
												class="fa fa-angle-double-up fa-lg text-white"
											/>
										</b-button>
										<b-button
											v-if="indexCargoPipeline !== 0"
											v-b-tooltip.hover.top="'Вверх'"
											type="button"
											variant="primary"
											class="main-info__cargo-pipelines-info-item-header-button mr-2"
											:disabled="loading"
											@click="
												changePlace(
													'up',
													indexCargoPipeline
												)
											"
										>
											<i
												class="fa fa-angle-up fa-lg text-white"
											/>
										</b-button>
										<b-button
											v-if="
												indexCargoPipeline !==
													form.cargoPipelines.length -
														1
											"
											v-b-tooltip.hover.top="'Вниз'"
											type="button"
											variant="primary"
											class="main-info__cargo-pipelines-info-item-header-button mr-2"
											:disabled="loading"
											@click="
												changePlace(
													'down',
													indexCargoPipeline
												)
											"
										>
											<i
												class="fa fa-angle-down fa-lg text-white"
											/>
										</b-button>
										<b-button
											v-if="
												indexCargoPipeline !==
													form.cargoPipelines.length -
														1
											"
											v-b-tooltip.hover.top="'В конец'"
											type="button"
											variant="primary"
											class="main-info__cargo-pipelines-info-item-header-button mr-2"
											:disabled="loading"
											@click="
												changePlace(
													'end',
													indexCargoPipeline
												)
											"
										>
											<i
												class="fa fa-angle-double-down fa-lg text-white"
											/>
										</b-button>
									</div>
								</div>
								<div
									class="main-info__cargo-pipelines-info-item-events"
								>
									<div
										v-for="(cargoPipelineEvent,
										indexCargoPipelineEvent) in cargoPipeline.cargoPipelineEvents"
										:key="
											`point-event-${indexCargoPipelineEvent}`
										"
										class="main-info__cargo-pipelines-info-item-event"
									>
										<div
											v-if="
												cargoPipelineEvent.formVisible &&
													form.cargoPipelineEditVisible
											"
											class="main-info__cargo-pipelines-info-item-body"
										>
											<div
												class="main-info__cargo-pipelines-info-item-body-header"
											>
												<p
													class="main-info__cargo-pipelines-info-item-subtitle mr-2"
												>
													Добавление события
												</p>
												<div
													class="main-info__cargo-pipelines-info-item-header-buttons"
												>
													<b-button
														v-b-tooltip.hover.top="
															'Удалить'
														"
														type="button"
														variant="danger"
														class="main-info__cargo-pipelines-info-item-header-button"
														:disabled="loading"
														@click="
															deletePlaceEvent(
																indexCargoPipeline,
																indexCargoPipelineEvent
															)
														"
													>
														<i
															class="fa fa-trash fa-lg text-white"
														/>
													</b-button>
												</div>
											</div>
											<b-row>
												<b-col
													v-if="
														(indexCargoPipeline !==
															0) ^
															(indexCargoPipeline ===
																0 &&
																indexCargoPipelineEvent !==
																	0)
													"
													md="6"
												>
													<div
														class="main-info__item"
													>
														<p
															class="main-info__item-title"
														>
															Воспользоваться
															услугами партнера
														</p>
														<b-form-checkbox
															v-model="
																cargoPipelineEvent
																	.partner
																	.switcher
															"
															@change="
																onCargoPipelinePartnerChange(
																	cargoPipelineEvent
																		.partner
																		.value,
																	indexCargoPipeline,
																	indexCargoPipelineEvent,
																	true
																)
															"
														>
															Да
														</b-form-checkbox>
													</div>
												</b-col>
												<b-col
													v-if="
														(indexCargoPipeline !==
															0) ^
															(indexCargoPipeline ===
																0 &&
																indexCargoPipelineEvent !==
																	0)
													"
													md="6"
												>
													<div
														class="main-info__item"
													>
														<p
															class="main-info__item-title form-group-required"
														>
															<label
																>Партнер</label
															>
														</p>
														<b-form-group
															class="form-group-el-select"
														>
															<el-select
																v-model="
																	cargoPipelineEvent
																		.partner
																		.value
																"
																class="form-control"
																placeholder="Начните название партнера"
																clearable
																:filterable="
																	true
																"
																:remote="true"
																reserve-keyword
																:remote-method="
																	cargoPipelinePartnerRemote
																"
																:loading="
																	loading
																"
																:disabled="
																	!cargoPipelineEvent
																		.partner
																		.switcher
																"
																@focus="
																	data.focusedCargoPipelinePartnerSelect = [
																		indexCargoPipeline,
																		indexCargoPipelineEvent,
																	]
																"
																@change="
																	onCargoPipelinePartnerChange(
																		cargoPipelineEvent
																			.partner
																			.value,
																		indexCargoPipeline,
																		indexCargoPipelineEvent
																	)
																"
																@clear="
																	cargoPipelinePartnerClear(
																		indexCargoPipeline,
																		indexCargoPipelineEvent
																	)
																"
															>
																<el-option
																	v-for="item in cargoPipelineEvent
																		.partner
																		.options"
																	:key="
																		item.value
																	"
																	:label="
																		item.text
																	"
																	:value="
																		item.value
																	"
																	:class="{
																		errored:
																			item.errored,
																	}"
																/>
															</el-select>
														</b-form-group>
													</div>
												</b-col>
												<b-col md="6">
													<div
														class="main-info__item"
													>
														<p
															class="main-info__item-title form-group-required"
														>
															<label
																>Название</label
															>
														</p>
														<b-form-group
															class="form-group-el-select"
														>
															<el-select
																v-model="
																	cargoPipelineEvent.value
																"
																class="form-control"
																name="car"
																placeholder="Выберите событие"
																clearable
																reserve-keyword
																:loading="
																	loading
																"
															>
																<el-option
																	v-for="item in data.cargoPipelineEventOptions"
																	:key="
																		item.value
																	"
																	:label="
																		item.text
																	"
																	:value="
																		item.value
																	"
																/>
															</el-select>
														</b-form-group>
													</div>
												</b-col>
												<b-col md="6">
													<div
														class="main-info__item"
													>
														<p
															class="main-info__item-title form-group-required"
														>
															<label
																>Местное дата и
																время</label
															>
														</p>
														<b-form-group
															id="firstPointArrivalDatetimeInputGroup2"
														>
															<el-date-picker-input
																id="first-point-arrival-datetime"
																v-model="
																	cargoPipelineEvent.cargoPipelineEventDate
																"
																type="datetime"
																placeholder=""
																format="dd.MM.yyyy HH:mm"
																:picker-options="
																	pickerOptions
																"
															/>
															<span
																class="main-info__item-desc-note"
																>Дата и время
																указаны в
																соотвествии с
																часовым поясом
																первой точки
																маршрута</span
															>
														</b-form-group>
													</div>
												</b-col>
												<b-col md="6">
													<div
														class="main-info__item"
													>
														<p
															class="main-info__item-title form-group-required"
														>
															<label
																>Автомобиль</label
															>
														</p>
														<b-form-group
															id="carInputGroup7"
															class="form-group-el-select"
														>
															<el-select
																v-model="
																	cargoPipelineEvent
																		.vehicle
																		.value
																"
																class="form-control"
																name="car"
																placeholder="Начните вводить ГРН автомобиля"
																clearable
																:filterable="
																	true
																"
																reserve-keyword
																:loading="
																	loading
																"
																:disabled="
																	cargoPipelineEvent
																		.partner
																		.switcher &&
																		!cargoPipelineEvent
																			.partner
																			.value
																"
																@input="
																	onCargoPipelinePartnerChange(
																		cargoPipelineEvent
																			.partner
																			.value,
																		indexCargoPipeline,
																		indexCargoPipelineEvent
																	)
																"
																@focus="
																	focusedCargoPipelineCarSelect(
																		cargoPipelineEvent
																			.partner
																			.value,
																		indexCargoPipeline,
																		indexCargoPipelineEvent
																	)
																"
																@change="
																	onCargoPipelineCarChange(
																		cargoPipelineEvent
																			.vehicle
																			.value,
																		indexCargoPipeline,
																		indexCargoPipelineEvent
																	)
																"
																@clear="
																	onCargoPipelinePartnerChange(
																		cargoPipelineEvent
																			.partner
																			.value,
																		indexCargoPipeline,
																		indexCargoPipelineEvent
																	)
																"
															>
																<el-option
																	v-for="item in cargoPipelineEvent
																		.vehicle
																		.options"
																	:key="
																		item.value
																	"
																	:label="
																		item.text
																	"
																	:value="
																		item.value
																	"
																	:class="{
																		errored:
																			item.errored,
																	}"
																/>
															</el-select>
															<!-- <small
                                v-if="data.car.blockedSecurity"
                                class="main-info__item-desc-note"
                              >Данное ТС не допущено службой безопасности</small> -->
															<small
																v-if="
																	cargoPipelineEvent
																		.vehicle
																		.data
																		.blockedSecurity
																"
																class="main-info__item-desc-note"
															>
																<span
																	style="color: red;"
																	>Данное ТС
																	не допущено
																	службой
																	безопасности,
																	невозможно
																	сохранить
																	событие</span
																>
															</small>
															<small
																v-if="
																	data.wrongTS
																"
																class="main-info__item-desc-note"
																>Указанный
																состав ТС не
																соответствует
																требованиям
																выигранного
																аукциона</small
															>
														</b-form-group>
														<div
															v-if="
																cargoPipelineEvent
																	.vehicle
																	.value
															"
															class="main-info__item-info-card"
														>
															<p
																class="main-info__item-info-card-title"
															>
																{{
																	cargoPipelineEvent
																		.vehicle
																		.data
																		.name
																}},
																{{
																	cargoPipelineEvent
																		.vehicle
																		.data
																		.registrationNumber
																}}
															</p>
															<ul
																v-if="
																	cargoPipelineEvent
																		.vehicle
																		.data
																		.type ===
																		'Тягач'
																"
																class="main-info__item-info-card-list"
															>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Тип ТС:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.type
																	}}
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Грузоподъемность:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.loadCapacity
																	}}
																	т
																</li>
															</ul>
															<ul
																v-else
																class="main-info__item-info-card-list"
															>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Тип ТС:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.type
																	}}
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Грузоподъемность:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.loadCapacity
																	}}
																	т
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Контейнеровоз:
																	<span
																		v-if="
																			cargoPipelineEvent
																				.vehicle
																				.data
																				.containerTransportVehicle
																		"
																		>Да</span
																	>
																	<span v-else
																		>Нет</span
																	>
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Высота
																	кузова:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.bodyHeight
																	}}
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Ширина
																	кузова:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.bodyWidth
																	}}
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Глубина
																	кузова:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.bodyDepth
																	}}
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Объем
																	кузова:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.bodyVolume
																	}}
																</li>
																<li
																	v-if="
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.bodyType
																	"
																	class="main-info__item-info-card-list-item"
																>
																	Тип кузова:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.bodyType
																	}}
																</li>
															</ul>
														</div>
													</div>
												</b-col>
												<b-col md="6">
													<div
														class="main-info__item"
													>
														<p
															class="main-info__item-title form-group-required"
														>
															<label
																>Водитель</label
															>
														</p>
														<b-form-group
															id="driverOneInputGroup6"
															class="form-group-el-select"
															:class="{
																errored: !(
																	cargoPipelineEvent
																		.driver
																		.data
																		.activeAttorney &&
																	cargoPipelineEvent
																		.driver
																		.data
																		.status ===
																		1
																),
															}"
														>
															<el-select
																v-model="
																	cargoPipelineEvent
																		.driver
																		.value
																"
																class="form-control"
																name="driver-one"
																placeholder="Начните вводить фамилию, имя и/или отчество"
																clearable
																:filterable="
																	true
																"
																reserve-keyword
																:loading="
																	loading
																"
																:disabled="
																	cargoPipelineEvent
																		.partner
																		.switcher &&
																		!cargoPipelineEvent
																			.partner
																			.value
																"
																@input="
																	onCargoPipelinePartnerChange(
																		cargoPipelineEvent
																			.partner
																			.value,
																		indexCargoPipeline,
																		indexCargoPipelineEvent
																	)
																"
																@focus="
																	focusedCargoPipelineDriverSelect(
																		cargoPipelineEvent
																			.partner
																			.value,
																		indexCargoPipeline,
																		indexCargoPipelineEvent
																	)
																"
																@change="
																	onCargoPipelineDriverChange(
																		cargoPipelineEvent
																			.driver
																			.value,
																		indexCargoPipeline,
																		indexCargoPipelineEvent
																	)
																"
																@clear="
																	onCargoPipelinePartnerChange(
																		cargoPipelineEvent
																			.partner
																			.value,
																		indexCargoPipeline,
																		indexCargoPipelineEvent
																	)
																"
															>
																<el-option
																	v-for="item in cargoPipelineEvent
																		.driver
																		.options"
																	:key="
																		item.value
																	"
																	:label="
																		item.text
																	"
																	:value="
																		item.value
																	"
																	:class="{
																		errored:
																			item.disabled,
																	}"
																/>
															</el-select>
															<small
																v-if="
																	cargoPipelineEvent
																		.driver
																		.value
																"
																class="main-info__item-desc-note"
															>
																<span
																	v-if="
																		!cargoPipelineEvent
																			.driver
																			.data
																			.activeAttorney &&
																			cargoPipelineEvent
																				.driver
																				.data
																				.status !==
																				1
																	"
																	>Данный
																	водитель не
																	верифицирован
																	и не имеет
																	активной
																	доверенности</span
																>
																<span
																	v-else-if="
																		cargoPipelineEvent
																			.driver
																			.data
																			.activeAttorney &&
																			cargoPipelineEvent
																				.driver
																				.data
																				.status !==
																				1
																	"
																	>Данный
																	водитель не
																	верифицирован</span
																>
																<span
																	v-else-if="
																		!cargoPipelineEvent
																			.driver
																			.data
																			.activeAttorney &&
																			cargoPipelineEvent
																				.driver
																				.data
																				.status ===
																				1
																	"
																	>Данный
																	водитель не
																	имеет
																	активной
																	доверенности</span
																>
															</small>
															<!-- <template
                                v-if="indexCargoPipeline === 0 && indexCargoPipelineEvent === 0"
                              >
                                <div
                                  v-if="cargoPipelineEvent.driver.value && !cargoPipelineEvent.driver.data.inn"
                                  class="main-info__item-desc-note text-danger"
                                >
                                  <p>Пожалуйста, укажите ИНН водителя, для возможности использовать его в рейсе</p>
                                  <driver-inn-edit
                                    v-model="cargoPipelineEvent.driver.data.inn"
                                    :contractor-id="form.contractor.value"
                                    :driver-id="cargoPipelineEvent.driver.value"
                                  />
                                </div>
                              </template> -->
														</b-form-group>
														<div
															v-if="
																cargoPipelineEvent
																	.driver
																	.value
															"
															class="main-info__item-info-card"
														>
															<p
																class="main-info__item-info-card-title"
															>
																{{
																	cargoPipelineEvent
																		.driver
																		.data
																		.fio
																}}
															</p>
															<ul
																class="main-info__item-info-card-list"
															>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	ИНН:
																	{{
																		cargoPipelineEvent
																			.driver
																			.data
																			.inn
																			? cargoPipelineEvent
																					.driver
																					.data
																					.inn
																			: "Отсутствует"
																	}}
																</li>
															</ul>
															<ul
																v-if="
																	cargoPipelineEvent
																		.driver
																		.data
																		.driverPhones
																"
																class="main-info__item-info-card-list"
															>
																<li
																	v-if="
																		cargoPipelineEvent
																			.driver
																			.data
																			.driverPhones
																			.length
																	"
																	class="main-info__item-info-card-list-item"
																>
																	Телефоны:
																	<span
																		v-for="(phone,
																		index) in cargoPipelineEvent
																			.driver
																			.data
																			.driverPhones"
																		:key="
																			index
																		"
																	>
																		<span
																			v-if="
																				phone.name
																			"
																			>{{
																				phone.name
																			}}:</span
																		>
																		<a
																			:href="
																				'tel:' +
																					phone.number
																			"
																			>{{
																				phone.number
																			}}</a
																		>;
																	</span>
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Допуск:
																	<span
																		v-if="
																			cargoPipelineEvent
																				.driver
																				.data
																				.driverAccessAuto
																		"
																		>Авто;
																	</span>
																	<span
																		v-if="
																			cargoPipelineEvent
																				.driver
																				.data
																				.driverAccessAuto ||
																				cargoPipelineEvent
																					.driver
																					.data
																					.driverAccessCargoFlow
																		"
																		>Контейнер;
																	</span>
																	<span
																		v-if="
																			cargoPipelineEvent
																				.driver
																				.data
																				.driverAccessAuto ||
																				cargoPipelineEvent
																					.driver
																					.data
																					.driverAccessContainer
																		"
																		>Грузопровод;
																	</span>
																</li>
																<li
																	v-if="
																		cargoPipelineEvent
																			.driver
																			.data
																			.attorneys
																			.length
																	"
																	class="main-info__item-info-card-list-item"
																>
																	Доверенности:
																	{{
																		cargoPipelineEvent
																			.driver
																			.data
																			.attorneys
																			.length
																	}}
																	<label
																		class="main-info__item-info-card-switcher"
																	>
																		<input
																			v-model="
																				cargoPipelineEvent
																					.driver
																					.data
																					.switcher
																			"
																			type="checkbox"
																			class="main-info__item-info-card-switcher-input"
																		/>
																		<span
																			class="main-info__item-info-card-switcher-display"
																		>
																			<span
																				v-if="
																					cargoPipelineEvent
																						.driver
																						.data
																						.switcher
																				"
																				>Скрыть
																				подробности<i
																					class="main-info__item-info-card-switcher-display-icon fa fa-arrow-up"
																			/></span>
																			<span
																				v-else
																				>Показать
																				подробности<i
																					class="main-info__item-info-card-switcher-display-icon fa fa-arrow-down"
																			/></span>
																		</span>
																	</label>
																</li>
																<ol
																	v-if="
																		cargoPipelineEvent
																			.driver
																			.data
																			.switcher &&
																			cargoPipelineEvent
																				.driver
																				.data
																				.attorneys
																				.length
																	"
																	class="main-info__item-info-card-list"
																>
																	<li
																		v-for="(attorney,
																		index) in cargoPipelineEvent
																			.driver
																			.data
																			.attorneys"
																		:key="
																			index
																		"
																		class="main-info__item-info-card-list-item"
																	>
																		<a
																			:href="
																				`/contractors/id${form.contractor.value}/attorney/id${attorney.id}`
																			"
																			target="_blank"
																			>Доверенность
																			№{{
																				attorney.number
																			}}</a
																		>
																		<span
																			v-if="
																				attorney.status ===
																					0
																			"
																			class="main-info__item-info-card-list-item-status bg-secondary"
																		>
																			На
																			рассмотрении</span
																		>
																		<span
																			v-else-if="
																				attorney.status ===
																					1
																			"
																			class="main-info__item-info-card-list-item-status bg-success"
																		>
																			Действует</span
																		>
																		<span
																			v-else-if="
																				attorney.status ===
																					2
																			"
																			class="main-info__item-info-card-list-item-status bg-danger"
																		>
																			Аннулирована</span
																		><br />
																		Действует
																		с
																		{{
																			attorney.dateStart
																		}}
																		по
																		{{
																			attorney.dateEnd
																		}}<br />
																		<span
																			v-if="
																				attorney.file
																			"
																		>
																			Загруженный
																			файл:
																			<a
																				:href="
																					'/files/' +
																						attorney
																							.file
																							.file
																							.guid
																				"
																				target="_blank"
																				@click.prevent="
																					downloadFile(
																						attorney
																							.file
																							.file
																					)
																				"
																				>{{
																					attorney
																						.file
																						.file
																						.originalName
																				}}</a
																			>
																		</span>
																	</li>
																</ol>
															</ul>
														</div>
													</div>
												</b-col>
											</b-row>
											<b-button
												type="button"
												variant="primary"
												class="route-pipelines-form__place-event-add-item-button"
												:disabled="
													cargoPipelineEvent.driver
														.value == '' ||
														cargoPipelineEvent
															.driver.value ==
															null ||
														cargoPipelineEvent
															.vehicle.value ==
															'' ||
														cargoPipelineEvent
															.vehicle.value ==
															null ||
														cargoPipelineEvent
															.vehicle.data
															.blockedSecurity ||
														cargoPipelineEvent.cargoPipelineEventDate ==
															null ||
														cargoPipelineEvent.value ==
															'' ||
														cargoPipelineEvent.value ==
															null ||
														(cargoPipelineEvent
															.partner.switcher &&
															(cargoPipelineEvent
																.partner
																.value == '' ||
																cargoPipelineEvent
																	.partner
																	.value ==
																	null))
												"
												@click="
													addEventToPlace(
														indexCargoPipeline,
														indexCargoPipelineEvent
													)
												"
											>
												Сохранить
											</b-button>
										</div>
										<div
											v-else
											class="main-info__cargo-pipelines-info-item-body"
										>
											<div
												class="main-info__cargo-pipelines-info-item-body-header"
											>
												<p
													class="main-info__cargo-pipelines-info-item-subtitle mr-2"
												>
													Событие:
													{{
														cargoPipelineEvent.name
													}}
													{{
														formatDatetime(
															cargoPipelineEvent.cargoPipelineEventDate
														)
													}}
													<span
														v-if="
															cargoPipelineEvent
																.partner
																.switcher
														"
													>
														({{
															cargoPipelineEvent
																.partner.name
														}})
													</span>
												</p>
												<div
													class="main-info__cargo-pipelines-info-item-header-buttons"
												>
													<b-button
														v-b-tooltip.hover.top="
															'Редактировать'
														"
														type="button"
														variant="primary"
														class="main-info__cargo-pipelines-info-item-header-button mr-2"
														@click="
															editPlaceEvent(
																indexCargoPipeline,
																indexCargoPipelineEvent
															)
														"
													>
														<i
															class="fa fa-pencil fa-lg text-white"
														/>
													</b-button>
													<b-button
														v-b-tooltip.hover.top="
															'Удалить'
														"
														type="button"
														variant="danger"
														class="main-info__cargo-pipelines-info-item-header-button mr-2"
														:disabled="loading"
														@click="
															deletePlaceEvent(
																indexCargoPipeline,
																indexCargoPipelineEvent
															)
														"
													>
														<i
															class="fa fa-trash fa-lg text-white"
														/>
													</b-button>
													<b-button
														v-if="
															indexCargoPipelineEvent !==
																0
														"
														v-b-tooltip.hover.top="
															'В начало'
														"
														type="button"
														variant="primary"
														class="main-info__cargo-pipelines-info-item-header-button mr-2"
														:disabled="loading"
														@click="
															changePlaceForEvent(
																'start',
																indexCargoPipeline,
																indexCargoPipelineEvent
															)
														"
													>
														<i
															class="fa fa-angle-double-up fa-lg text-white"
														/>
													</b-button>
													<b-button
														v-if="
															indexCargoPipelineEvent !==
																0
														"
														v-b-tooltip.hover.top="
															'Вверх'
														"
														type="button"
														variant="primary"
														class="main-info__cargo-pipelines-info-item-header-button mr-2"
														:disabled="loading"
														@click="
															changePlaceForEvent(
																'up',
																indexCargoPipeline,
																indexCargoPipelineEvent
															)
														"
													>
														<i
															class="fa fa-angle-up fa-lg text-white"
														/>
													</b-button>
													<b-button
														v-if="
															indexCargoPipelineEvent !==
																form
																	.cargoPipelines[
																	indexCargoPipeline
																]
																	.cargoPipelineEvents
																	.length -
																	1
														"
														v-b-tooltip.hover.top="
															'Вниз'
														"
														type="button"
														variant="primary"
														class="main-info__cargo-pipelines-info-item-header-button mr-2"
														:disabled="loading"
														@click="
															changePlaceForEvent(
																'down',
																indexCargoPipeline,
																indexCargoPipelineEvent
															)
														"
													>
														<i
															class="fa fa-angle-down fa-lg text-white"
														/>
													</b-button>
													<b-button
														v-if="
															indexCargoPipelineEvent !==
																form
																	.cargoPipelines[
																	indexCargoPipeline
																]
																	.cargoPipelineEvents
																	.length -
																	1
														"
														v-b-tooltip.hover.top="
															'В конец'
														"
														type="button"
														variant="primary"
														class="main-info__cargo-pipelines-info-item-header-button mr-2"
														:disabled="loading"
														@click="
															changePlaceForEvent(
																'end',
																indexCargoPipeline,
																indexCargoPipelineEvent
															)
														"
													>
														<i
															class="fa fa-angle-double-down fa-lg text-white"
														/>
													</b-button>
												</div>
											</div>
											<b-row>
												<b-col md="6">
													<div
														class="main-info__item"
													>
														<p
															class="main-info__item-title"
														>
															Автомобиль
														</p>
														<div
															v-if="
																cargoPipelineEvent
																	.vehicle
																	.value
															"
															class="main-info__item-info-card"
														>
															<p
																class="main-info__item-info-card-title"
															>
																{{
																	cargoPipelineEvent
																		.vehicle
																		.data
																		.name
																}},
																{{
																	cargoPipelineEvent
																		.vehicle
																		.data
																		.registrationNumber
																}}
															</p>
															<ul
																v-if="
																	cargoPipelineEvent
																		.vehicle
																		.data
																		.type ===
																		'Тягач'
																"
																class="main-info__item-info-card-list"
															>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Тип ТС:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.type
																	}}
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Грузоподъемность:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.loadCapacity
																	}}
																	т
																</li>
															</ul>
															<ul
																v-else
																class="main-info__item-info-card-list"
															>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Тип ТС:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.type
																	}}
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Грузоподъемность:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.loadCapacity
																	}}
																	т
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Контейнеровоз:
																	<span
																		v-if="
																			cargoPipelineEvent
																				.vehicle
																				.data
																				.containerTransportVehicle
																		"
																		>Да</span
																	>
																	<span v-else
																		>Нет</span
																	>
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Высота
																	кузова:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.bodyHeight
																	}}
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Ширина
																	кузова:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.bodyWidth
																	}}
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Глубина
																	кузова:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.bodyDepth
																	}}
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Объем
																	кузова:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.bodyVolume
																	}}
																</li>
																<li
																	v-if="
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.bodyType
																	"
																	class="main-info__item-info-card-list-item"
																>
																	Тип кузова:
																	{{
																		cargoPipelineEvent
																			.vehicle
																			.data
																			.bodyType
																	}}
																</li>
															</ul>
														</div>
													</div>
												</b-col>
												<b-col md="6">
													<div
														class="main-info__item"
													>
														<p
															class="main-info__item-title"
														>
															Водитель
														</p>
														<div
															v-if="
																cargoPipelineEvent
																	.driver
																	.value
															"
															class="main-info__item-info-card"
														>
															<p
																class="main-info__item-info-card-title"
															>
																{{
																	cargoPipelineEvent
																		.driver
																		.data
																		.fio
																}}
															</p>
															<ul
																class="main-info__item-info-card-list"
															>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	ИНН:
																	{{
																		cargoPipelineEvent
																			.driver
																			.data
																			.inn
																			? cargoPipelineEvent
																					.driver
																					.data
																					.inn
																			: "Отсутствует"
																	}}
																</li>
															</ul>
															<ul
																v-if="
																	cargoPipelineEvent
																		.driver
																		.data
																		.driverPhones
																"
																class="main-info__item-info-card-list"
															>
																<li
																	v-if="
																		cargoPipelineEvent
																			.driver
																			.data
																			.driverPhones
																			.length
																	"
																	class="main-info__item-info-card-list-item"
																>
																	Телефоны:
																	<span
																		v-for="(phone,
																		index) in cargoPipelineEvent
																			.driver
																			.data
																			.driverPhones"
																		:key="
																			index
																		"
																	>
																		<span
																			v-if="
																				phone.name
																			"
																			>{{
																				phone.name
																			}}:</span
																		>
																		<a
																			:href="
																				'tel:' +
																					phone.number
																			"
																			>{{
																				phone.number
																			}}</a
																		>;
																	</span>
																</li>
																<li
																	class="main-info__item-info-card-list-item"
																>
																	Допуск:
																	<span
																		v-if="
																			cargoPipelineEvent
																				.driver
																				.data
																				.driverAccessAuto
																		"
																		>Авто;
																	</span>
																	<span
																		v-if="
																			cargoPipelineEvent
																				.driver
																				.data
																				.driverAccessAuto ||
																				cargoPipelineEvent
																					.driver
																					.data
																					.driverAccessCargoFlow
																		"
																		>Контейнер;
																	</span>
																	<span
																		v-if="
																			cargoPipelineEvent
																				.driver
																				.data
																				.driverAccessAuto ||
																				cargoPipelineEvent
																					.driver
																					.data
																					.driverAccessContainer
																		"
																		>Грузопровод;
																	</span>
																</li>
																<li
																	v-if="
																		cargoPipelineEvent
																			.driver
																			.data
																			.attorneys
																			.length
																	"
																	class="main-info__item-info-card-list-item"
																>
																	Доверенности:
																	{{
																		cargoPipelineEvent
																			.driver
																			.data
																			.attorneys
																			.length
																	}}
																	<label
																		class="main-info__item-info-card-switcher"
																	>
																		<input
																			v-model="
																				cargoPipelineEvent
																					.driver
																					.data
																					.switcher
																			"
																			type="checkbox"
																			class="main-info__item-info-card-switcher-input"
																		/>
																		<span
																			class="main-info__item-info-card-switcher-display"
																		>
																			<span
																				v-if="
																					cargoPipelineEvent
																						.driver
																						.data
																						.switcher
																				"
																				>Скрыть
																				подробности<i
																					class="main-info__item-info-card-switcher-display-icon fa fa-arrow-up"
																			/></span>
																			<span
																				v-else
																				>Показать
																				подробности<i
																					class="main-info__item-info-card-switcher-display-icon fa fa-arrow-down"
																			/></span>
																		</span>
																	</label>
																</li>
																<ol
																	v-if="
																		cargoPipelineEvent
																			.driver
																			.data
																			.switcher &&
																			cargoPipelineEvent
																				.driver
																				.data
																				.attorneys
																				.length
																	"
																	class="main-info__item-info-card-list"
																>
																	<li
																		v-for="(attorney,
																		index) in cargoPipelineEvent
																			.driver
																			.data
																			.attorneys"
																		:key="
																			index
																		"
																		class="main-info__item-info-card-list-item"
																	>
																		<a
																			:href="
																				`/contractors/id${form.contractor.value}/attorney/id${attorney.id}`
																			"
																			target="_blank"
																			>Доверенность
																			№{{
																				attorney.number
																			}}</a
																		>
																		<span
																			v-if="
																				attorney.status ===
																					0
																			"
																			class="main-info__item-info-card-list-item-status bg-secondary"
																		>
																			На
																			рассмотрении</span
																		>
																		<span
																			v-else-if="
																				attorney.status ===
																					1
																			"
																			class="main-info__item-info-card-list-item-status bg-success"
																		>
																			Действует</span
																		>
																		<span
																			v-else-if="
																				attorney.status ===
																					2
																			"
																			class="main-info__item-info-card-list-item-status bg-danger"
																		>
																			Аннулирована</span
																		><br />
																		Действует
																		с
																		{{
																			attorney.dateStart
																		}}
																		по
																		{{
																			attorney.dateEnd
																		}}<br />
																		<span
																			v-if="
																				attorney.file
																			"
																		>
																			Загруженный
																			файл:
																			<a
																				:href="
																					'/files/' +
																						attorney
																							.file
																							.file
																							.guid
																				"
																				target="_blank"
																				@click.prevent="
																					downloadFile(
																						attorney
																							.file
																							.file
																					)
																				"
																				>{{
																					attorney
																						.file
																						.file
																						.originalName
																				}}</a
																			>
																		</span>
																	</li>
																</ol>
															</ul>
														</div>
													</div>
												</b-col>
											</b-row>
										</div>
									</div>
								</div>
							</div>
						</div>
						<b-button
							type="button"
							variant="primary"
							class="route-pipelines-form__place-add-button"
							:class="{ loading: loading }"
							:disabled="
								form.cargoPipelinePlacesEvent.visible ||
									form.contractor.value === ''
							"
							@click="
								form.cargoPipelinePlacesEvent.visible = true
							"
						>
							Добавить место
						</b-button>
						<div class="route-pipelines-form">
							<div class="route-pipelines-form__place-add">
								<div
									v-if="form.cargoPipelinePlacesEvent.visible"
									class="route-pipelines-form__place-add-item"
								>
									<div class="main-info__item">
										<p class="main-info__item-title">
											Новое место
										</p>
										<b-form-group
											id="cargoPipelinePlacesEventInputGroup9"
											class="form-group-el-select"
										>
											<el-select
												v-model="
													form
														.cargoPipelinePlacesEvent
														.value
												"
												class="form-control"
												name="trailer"
												placeholder="Начните вводить место"
												clearable
												:filterable="true"
												:remote="true"
												reserve-keyword
												:remote-method="
													cargoPipelinePlacesEventRemote
												"
												:loading="loading"
												@clear="
													cargoPipelinePlacesEventRemote(
														''
													)
												"
											>
												<el-option
													v-for="item in form
														.cargoPipelinePlacesEvent
														.options"
													:key="item.value"
													:label="item.text"
													:value="item.value"
													:class="{
														errored: item.errored,
													}"
												/>
											</el-select>
										</b-form-group>
									</div>
									<div
										class="d-flex justify-content-between w-40"
									>
										<b-button
											type="button"
											variant="primary"
											class="route-pipelines-form__place-add-item-button"
											:class="{ loading: loading }"
											:disabled="
												!form.cargoPipelinePlacesEvent
													.value
											"
											@click="addPlaceToCargoPipeline()"
										>
											Добавить в грузопровод
										</b-button>
										<b-button
											type="button"
											variant="primary"
											class="route-pipelines-form__place-add-item-button"
											:class="{ loading: loading }"
											@click="
												form.cargoPipelinePlacesEvent.visible = !form
													.cargoPipelinePlacesEvent
													.visible
											"
										>
											Отменить
										</b-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="fixed-buttons">
					<b-button
						type="button"
						variant="secondary"
						class="mr-2"
						:class="{ loading: loading }"
						@click="$router.go(-1)"
					>
						Назад
					</b-button>
					<b-button
						type="submit"
						variant="primary"
						class="mr-2"
						:class="{ loading: loading }"
						:disabled="
							loading ||
								!form.playedRouteWay.value ||
								!route.type ||
								!form.responsible.value ||
								!form.organizations.value ||
								!form.firstPointArrivalDatetime
						"
					>
						Создать рейс
					</b-button>
					<b-button
						type="button"
						variant="success"
						:class="{ loading: loading }"
						:disabled="
							loading ||
								!form.playedRouteWay.value ||
								!route.type ||
								!form.responsible.value ||
								!form.organizations.value ||
								!form.firstPointArrivalDatetime ||
								!$store.getters.isAdminOrManager ||
								!!form.contractor.value
						"
						@click="customersRouteAuctionCreate"
					>
						Создать рейс и перевести в Аукцион
					</b-button>
				</div>
			</div>
		</b-form>
	</div>
</template>

<script>
import DriverInnEdit from "../../components/DriverInnEdit.vue";
import ElDatePickerInput from "../../components/ElDatePickerInput.vue";
import ElSelectClearable from "../../components/ElSelectClearable.vue";
import Vue from "vue";
import { ClientTable, Event } from "vue-tables-2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import notifications from "../../components/mixins/notifications";
import { numberToRUFormat } from "@/components/mixins/helpers";
import {
	routeWaysShortlist,
	customersContractorsList,
	contractorsPartnersDriversList,
	customersContractorRead,
	getCustomerRouteOwner,
	contractorsPartnerList,
	contractorsDriverList,
	contractorsDriverRead,
	contractorsTransportVehiclesList,
	contractorsTransportVehicleRead,
	contractorsContainersList,
	contractorsPartnersTransportVehiclesList,
	contractorsContainerRead,
	customersRouteUpdate,
	checkContractorsDriverByDriverId,
	cargoPipelinePlacesEvent,
	cargoPipelineEvent,
	downloadFile,
	routeOwnersList,
	organizationsList,
	routesTransportationTypesList,
	routeWayRead,
	customersRouteCreate,
	customersRouteAuctionCreate,
} from "../../services/api";
import moment from "moment";
import tz from "moment-timezone";

Vue.use(ClientTable);

export default {
	name: "CustomerFlightsAdd",
	components: {
		Event,
		ElSelectClearable,
		ElDatePickerInput,
		DriverInnEdit,
	},
	mixins: [validationMixin, notifications],
	props: {
		flightId: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			updateButton: null,
			data: {
				defaultOrganization: null,
				cargoPipelineEventOptions: [],
				focusedCargoPipelinePartnersSelect: [],
				focusedCargoPipelineDriverSelect: [],
				focusedCargoPipelineCarSelect: [],
				responsible: {},
				driverOne: {
					attorneys: [],
					driverPhones: [],
				},
				driverTwo: {
					attorneys: [],
					driverPhones: [],
				},
				car: {
					containerTransportVehicle: false,
				},
				carContainer: [],
				trailer: {
					containerTransportVehicle: false,
				},
				trailerContainer: [],
				wrongTS: false,
			},
			form: {
				playedRouteWay: {
					options: [],
					value: "",
				},
				routeTransportations: {
					value: "",
					options: [],
				},
				cargoFlow: false,
				cargoPipelines: [],
				cargoPipelinePlacesEvent: {
					visible: false,
					value: null,
					options: [],
				},
				comment: {
				visible: false,
				value: null,
				},
				cargoPipelineEditVisible: false,
				size: null,
				loadingUnloading: [],
				bodyType: [],
				mkadPass: false,
				retryDriver: false,
				organizations: {
					value: null,
					options: [],
				},
				firstPointArrivalDatetime: "",
				firstPointLoadingDatetime: "",
				freightSumm: null,
				accelerated: false,
				responsible: {
					options: [],
					value: "",
				},
				contractor: {
					options: [],
					value: "",
				},
				driverOne: {
					options: [],
					value: "",
				},
				driverTwo: {
					options: [],
					value: "",
				},
				car: {
					options: [],
					value: "",
				},
				carContainer: {
					options: [],
					value: [],
				},
				trailer: {
					options: [],
					value: "",
				},
				trailerContainer: {
					options: [],
					value: [],
				},
			},
			route: {
				auction: null,
				firstRouteWayTimezone: null,
				code: "",
				name: "",
				typeGuid: "",
				type: "",
				typeId: null,
				columns: [
					"rowNumber",
					"department.name",
					"department.mainWarehouse.address",
					"timeSummer",
					"distance",
				],
        transportation: {
          name: null,
          guid: null,
        },
				options: {
					headings: {
						rowNumber: "№",
						"department.name": "Наименование Отделения",
						"department.mainWarehouse.address":
							"Адрес точки Маршрута",
						timeSummer: "Ориентировочное время в пути",
						distance: "Расстояние",
					},
					sortable: [],
					filterable: [],
					sortIcon: {
						base: "fa",
						up: "fa-sort-asc",
						down: "fa-sort-desc",
						is: "fa-sort",
					},
					orderBy: {
						column: "rowNumber",
					},
					pagination: {
						chunk: 10,
						edge: true,
						nav: "fixed",
					},
					texts: {
						count:
							"Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись",
						first: "Первая",
						last: "Последняя",
						filter: "Фильтр:",
						filterPlaceholder: "Поисковый запрос",
						limit: "Записи:",
						page: "Страница:",
						noResults: "Нет совпадающих записей",
						filterBy: "Фильтр по {column}",
						loading: "Загрузка...",
						defaultOption: "Выбор {column}",
						columns: "Столбцы",
					},
				},
				points: [],
				movementPoints: [],
				fullDistance: "",
				theme: "bootstrap4",
			},
			pickerOptions: {
				firstDayOfWeek: 1,
			},
			loading: false,
			useVuex: false,
			template: "default",
		};
	},
	computed: {
		formStr() {
			const calculationFormData = {};
			if (this.form.playedRouteWay.value) {
				calculationFormData.routeWay = {
					id: this.form.playedRouteWay.value,
				};
			}
			calculationFormData.freightSumm = 0;
			if (this.form.routeTransportations.value) {
				calculationFormData.transportation = {
					id: this.form.routeTransportations.value,
				};
			}
			if (this.route.typeId) {
				calculationFormData.transportationType = {
					id: this.route.typeId,
				};
			}
			if (this.form.comment.value && this.form.comment.value !== "") {
				calculationFormData.commentValue = this.form.comment.value;
			}
      /*if (this.form.comment.value && this.form.comment.value !== "") {
        Vue.set(
          this.route,
          "comment",
          {value: this.form.comment.value},
        );
      }*/
			if (
				this.form.carContainer.value.length ||
				this.form.trailerContainer.value.length
			) {
				calculationFormData.routeContainers = [];
			}
			if (this.form.firstPointArrivalDatetime) {
				calculationFormData.planDateOfFirstPointArrive = moment(
					this.form.firstPointArrivalDatetime
				)
					.tz(this.route.firstRouteWayTimezone, true)
					.format(); // Плановая дата и время прибытия в первую точку маршрута
			}
			if (this.form.firstPointLoadingDatetime) {
				calculationFormData.planDateOfFirstPointLoading = moment(
					this.form.firstPointLoadingDatetime
				)
					.tz(this.route.firstRouteWayTimezone, true)
					.format(); // Плановая дата и время начала погрузки в первой точке маршрута
			}
			if (this.form.organizations.value) {
				calculationFormData.organization = {
					id: this.form.organizations.value,
				};
			}
			calculationFormData.boostFlag = this.form.accelerated; // Ускоренный
			if (this.form.responsible.value) {
				calculationFormData.naRouteOwner = {
					id: this.form.responsible.value,
				}; // Сотрудник ответственный за Рейс
			}
			if (this.form.contractor.value) {
				calculationFormData.contractor = {
					id: this.form.contractor.value,
				}; // Перевозчик
			}
			if (this.form.driverOne.value && !this.form.driverTwo.value) {
				calculationFormData.driverOne = {};
				calculationFormData.driverOne.id = this.data.driverOne.id; // Водитель 1
				delete calculationFormData.driverTwo;
			} else if (
				!this.form.driverOne.value &&
				this.form.driverTwo.value
			) {
				calculationFormData.driverOne = {};
				calculationFormData.driverOne.id = this.data.driverTwo.id; // Водитель 1
				delete calculationFormData.driverTwo;
			} else if (this.form.driverOne.value && this.form.driverTwo.value) {
				calculationFormData.driverOne = {};
				calculationFormData.driverOne.id = this.data.driverOne.id; // Водитель 1
				calculationFormData.driverTwo = {};
				calculationFormData.driverTwo.id = this.data.driverTwo.id; // Водитель 2
			}
			if (this.form.cargoFlow) {
				calculationFormData.cargoFlow = this.form.cargoFlow;
				if (this.form.trailer.value) {
					calculationFormData.trailer = {};
					calculationFormData.trailer.id = this.data.trailer.id; // Прицеп
				}
				if (this.form.cargoPipelines.length > 0) {
					calculationFormData.cargoPipelines = [];
					let rowCounter = 1;
					calculationFormData.planDateOfFirstPointArrive = moment(
						this.form.cargoPipelines[0].cargoPipelineEvents[0]
							.cargoPipelineEventDate
					)
						.tz(this.route.firstRouteWayTimezone, true)
						.format(); // Плановая дата и время прибытия в первую точку маршрута
					this.form.cargoPipelines.forEach((cargoPipeline) => {
						let cargoPipelineEventSendFormat = {};
						cargoPipeline.cargoPipelineEvents.forEach(
							(cargoPipelineEvent) => {
								cargoPipelineEventSendFormat.cargoPipelinePlaceOfEvent = {
									id:
										cargoPipeline.cargoPipelinePlaceOfEvent
											.id,
								};
								cargoPipelineEventSendFormat.rowNumber = rowCounter;
								rowCounter = rowCounter + 1;
								if (
									cargoPipelineEvent.partner.switcher &&
									cargoPipelineEvent.partner.value
								) {
									cargoPipelineEventSendFormat.partner = {
										id: cargoPipelineEvent.partner.value,
									};
								}
								if (cargoPipelineEvent.value) {
									cargoPipelineEventSendFormat.cargoPipelineEvent = {
										id: cargoPipelineEvent.value,
									};
								}
								if (cargoPipelineEvent.cargoPipelineEventDate) {
									cargoPipelineEventSendFormat.cargoPipelineEventDateTz = this.route.firstRouteWayTimezone;
									cargoPipelineEventSendFormat.cargoPipelineEventDate = moment(
										cargoPipelineEvent.cargoPipelineEventDate
									)
										.tz(
											this.route.firstRouteWayTimezone,
											true
										)
										.format();
								}
								if (cargoPipelineEvent.vehicle.data.id) {
									cargoPipelineEventSendFormat.vehicle = {
										id: cargoPipelineEvent.vehicle.data.id,
									};
								}
								if (cargoPipelineEvent.driver.data.id) {
									cargoPipelineEventSendFormat.driver = {
										id: cargoPipelineEvent.driver.data.id,
									};
								}
								calculationFormData.cargoPipelines.push(
									cargoPipelineEventSendFormat
								);
								cargoPipelineEventSendFormat = {};
							}
						);
					});
				}
			} else {
				if (this.form.car.value) {
					calculationFormData.transport = {};
					calculationFormData.transport.id = this.data.car.id; // Автомобиль
					if (this.form.carContainer.value.length) {
						this.form.carContainer.value.map((container) => {
							// Контейнеры автомобиля
							calculationFormData.routeContainers.push({
								vehicle: { id: this.data.car.id },
								container: { id: container },
							});
						});
					}
				}
				if (this.form.trailer.value) {
					calculationFormData.trailer = {};
					calculationFormData.trailer.id = this.data.trailer.id; // Прицеп
					if (this.form.trailerContainer.value.length) {
						this.form.trailerContainer.value.map((container) => {
							// Контейнеры прицепа
							calculationFormData.routeContainers.push({
								vehicle: { id: this.data.trailer.id },
								container: { id: container },
							});
						});
					}
				}
			}
			if (calculationFormData.routeContainers) {
				if (!calculationFormData.routeContainers.length) {
					delete calculationFormData.routeContainers;
				}
			}
			return calculationFormData;
		},
		isValid() {
			return !this.$v.form.$anyError;
		},
	},
	validations: {
		form: {
			route: {
				options: [],
				value: "",
			},
			firstPointArrivalDatetime: "",
			firstPointLoadingDatetime: "",
			responsible: {
				options: [],
				value: "",
			},
			contractor: {
				options: [],
				value: "",
			},
			driver: {
				options: [],
				value: "",
			},
			car: {
				options: [],
				value: "",
			},
			trailer: {
				options: [],
				value: "",
			},
			container: {
				options: [],
				value: "",
			},
		},
	},
	mounted() {
		this.routeWaysShortlist("");
		this.routesTransportationTypesList();
		this.contractorRemote("");
		this.responsibleRemote("");
		this.getOrganizationsList();
		this.cargoPipelinePlacesEventRemote();
		this.cargoPipelineEventRemote();
	},
	methods: {
		downloadFile,
		formatDatetime(date) {
			return date ? moment(date).format("DD.MM.YYYY HH:mm") : "";
		},
		async routeWaysShortlist(name) {
			this.loading = true;
			const response = await routeWaysShortlist({
				query: name,
				limit: 100,
			});
			if (response && response.status === 200) {
				this.form.playedRouteWay.options = response.data.items.map(
					(item) => {
						let itemCode = item.code;
						if (item.transportationType && item.transportationType.name) {
						  itemCode = `${item.code} (${item.transportationType.name})`
						}
						return { value: item.id, text: itemCode };
					}
				);
			}
			this.loading = false;
		},
		async routesTransportationTypesList() {
			this.loading = true;
			const response = await routesTransportationTypesList({
				limit: 100,
			});
			if (response && response.status === 200) {
				this.form.routeTransportations.options = response.data.items.map(
					(item) => {
						return {
							value: item.id,
							text: item.name,
							color: item.color,
							description: item.description,
						};
					}
				);
			}
			this.loading = false;
		},
		async onRouteChange(value) {
			if (value) {
				this.loading = true;
				const response = await routeWayRead(value);
				if (response && response.status === 200) {
          if (response.data.routes) {
          response.data.routes.forEach((route) => {
            if (route.comment && route.comment.comment !== undefined) {
              this.route.comments.id.push(route.comment.id);
              this.route.comments.value.push(route.comment.comment);
            }
          });
        }
					this.route.name = response.data.name;
					if (response.data.transportationType) {
						this.route.typeGuid =
							response.data.transportationType.guid;
						this.route.type = response.data.transportationType.name;
						this.route.typeId = response.data.transportationType.id;
					}
					this.route.points = response.data.routeWayPoints.sort(
						(a, b) => a.rowNumber - b.rowNumber
					);
					if (
						(this.route.transportation.guid === "4b714ab4-4c44-4578-9a7a-06dbe77d8c45" ||
							this.route.transportation.guid ===
								"9ec087d0-917c-4fbb-b714-80ab7603fd6e")
            ) {
						this.route.points.forEach((item, index) => {
              if ( this.route.movementPoints[index].pointAddress && this.route.movementPoints[index].pointAddress !== "") {
                item.department.mainWarehouse.address = this.route.movementPoints[
                  index
                ].pointAddress;
              }
            });
					}
					this.route.firstRouteWayTimezone = this.route.points[0].department.timeZone;
					this.route.fullDistance = response.data.routeWayPoints
						.reduce(function(accumulator, currentValue) {
							return accumulator + Number(currentValue.distance);
						}, 0)
						.toFixed(2);
				}
				this.loading = false;
			}
		},
		onSelectContractorChange(contractorId) {
			this.onContractorChange(contractorId);
			this.clearContractorEntities();
			if (this.form.cargoFlow) {
				this.trailerRemote("");
				this.trailerContainerRemote("");
			} else {
				this.driverOneRemote("");
				this.driverTwoRemote("");
				this.carRemote("");
				this.carContainerRemote("");
				this.trailerRemote("");
				this.trailerContainerRemote("");
			}
		},
		onSelectContractorClear() {
			this.contractorRemote("");
			this.clearContractorEntities();
		},
		clearContractorEntities() {
			this.form.driverOne.value = "";
			this.form.driverTwo.value = "";
			this.form.car.value = "";
			this.form.carContainer.value = [];
			this.form.trailer.value = "";
			this.form.trailerContainer.value = [];
		},
		onCargoFlowChange(cargoFlow) {
			this.form.firstPointArrivalDatetime = "";
			this.form.driverOne.value = null;
			this.form.driverTwo.value = null;
			if (!cargoFlow) {
				if (this.form.contractor.value) {
					this.driverOneRemote("");
					this.driverTwoRemote("");
					this.carRemote("");
					this.carContainerRemote("");
					this.trailerRemote("");
					this.trailerContainerRemote("");
				}
			} else {
				this.form.accelerated = false;
			}
		},
		onCargoPipelineEventDateChange() {
			if (
				this.form.cargoPipelines.length > 0 &&
				this.form.cargoPipelines[0].cargoPipelineEvents.length > 0 &&
				this.form.cargoPipelines[0].cargoPipelineEvents[0]
					.cargoPipelineEventDate
			) {
				this.form.firstPointArrivalDatetime = this.form.cargoPipelines[0].cargoPipelineEvents[0].cargoPipelineEventDate;
			}
		},
    addComment() {
    this.form.comment.visible = true;
    },
    saveComment() {
    this.showSuccess("Комментарий сохранён");
	this.updateButton = true;
    },
	updateComment() {
		this.showSuccess("Комментарий обновлён");
	},
		addPlaceToCargoPipeline() {
			this.form.cargoPipelinePlacesEvent.visible = false;
			this.form.cargoPipelineEditVisible = true;
			this.form.cargoPipelines.push({
				cargoPipelinePlaceOfEvent: {
					id: this.form.cargoPipelinePlacesEvent.value,
					name: this.form.cargoPipelinePlacesEvent.options.filter(
						(item) =>
							item.value ===
							this.form.cargoPipelinePlacesEvent.value
					)[0].text,
				},
				cargoPipelineEvents: [
					{
						formVisible: true,
						value: null,
						name: null,
						cargoPipelineEventDate: null,
						partner: {
							switcher: false,
							value: null,
							options: [],
							name: null,
						},
						driver: {
							value: null,
							options: [],
							data: {},
						},
						vehicle: {
							value: null,
							options: [],
							data: {},
						},
					},
				],
			});
			const newCargoPipelineIndex = this.form.cargoPipelines.length - 1;
			const newCargoPipelineEventIndex =
				this.form.cargoPipelines[newCargoPipelineIndex]
					.cargoPipelineEvents.length - 1;
			this.cargoPipelinePartnerRemote(
				"",
				newCargoPipelineIndex,
				newCargoPipelineEventIndex
			);
			this.cargoPipelineCarRemote(
				"",
				newCargoPipelineIndex,
				newCargoPipelineEventIndex
			);
			this.cargoPipelineDriverRemote(
				"",
				newCargoPipelineIndex,
				newCargoPipelineEventIndex
			);
			this.form.cargoPipelinePlacesEvent.value = null;
		},
		addNewEventToPlace(indexCargoPipeline) {
			this.form.cargoPipelineEditVisible = true;
			this.form.cargoPipelines[
				indexCargoPipeline
			].cargoPipelineEvents.push({
				formVisible: true,
				value: null,
				name: null,
				cargoPipelineEventDate: null,
				partner: {
					switcher: false,
					value: null,
					options: [],
					name: null,
				},
				driver: {
					value: null,
					options: [],
					data: {},
				},
				vehicle: {
					value: null,
					options: [],
					data: {},
				},
			});
			const newCargoPipelineEventIndex =
				this.form.cargoPipelines[indexCargoPipeline].cargoPipelineEvents
					.length - 1;
			this.cargoPipelinePartnerRemote(
				"",
				indexCargoPipeline,
				newCargoPipelineEventIndex
			);
			this.cargoPipelineCarRemote(
				"",
				indexCargoPipeline,
				newCargoPipelineEventIndex
			);
			this.cargoPipelineDriverRemote(
				"",
				indexCargoPipeline,
				newCargoPipelineEventIndex
			);
		},
		addEventToPlace(indexCargoPipeline, indexCargoPipelineEvent) {
			this.form.cargoPipelines[indexCargoPipeline].cargoPipelineEvents[
				indexCargoPipelineEvent
			].formVisible = false;
			this.form.cargoPipelineEditVisible = false;
			this.form.cargoPipelines[indexCargoPipeline].cargoPipelineEvents[
				indexCargoPipelineEvent
			].name = this.data.cargoPipelineEventOptions.filter(
				(item) =>
					item.value ===
					this.form.cargoPipelines[indexCargoPipeline]
						.cargoPipelineEvents[indexCargoPipelineEvent].value
			)[0].text;
		},
		editPlaceEvent(indexCargoPipeline, indexCargoPipelineEvent) {
			this.form.cargoPipelines[indexCargoPipeline].cargoPipelineEvents[
				indexCargoPipelineEvent
			].formVisible = true;
			this.form.cargoPipelineEditVisible = true;
		},
		changePlace(event, indexCargoPipeline) {
			if (event === "start") {
				if (indexCargoPipeline === 1) {
					this.form.cargoPipelines[
						indexCargoPipeline
					].cargoPipelineEvents.forEach(
						(cargoPipelineEvent, indexCargoPipelineEvent) => {
							if (cargoPipelineEvent.partner.switcher) {
								this.form.cargoPipelines[
									indexCargoPipeline
								].cargoPipelineEvents[
									indexCargoPipelineEvent
								].partner.switcher = false;
								this.form.cargoPipelines[
									indexCargoPipeline
								].cargoPipelineEvents[
									indexCargoPipelineEvent
								].partner.value = null;
								this.form.cargoPipelines[
									indexCargoPipeline
								].cargoPipelineEvents[
									indexCargoPipelineEvent
								].partner.name = null;
								this.form.cargoPipelines[
									indexCargoPipeline
								].cargoPipelineEvents[
									indexCargoPipelineEvent
								].driver.value = null;
								this.form.cargoPipelines[
									indexCargoPipeline
								].cargoPipelineEvents[
									indexCargoPipelineEvent
								].vehicle.value = null;
								this.cargoPipelineCarRemote(
									"",
									indexCargoPipeline,
									indexCargoPipelineEvent
								);
								this.cargoPipelineDriverRemote(
									"",
									indexCargoPipeline,
									indexCargoPipelineEvent
								);
							}
						}
					);
				}
				this.form.cargoPipelines.unshift(
					this.form.cargoPipelines.splice(indexCargoPipeline, 1)[0]
				);
			} else if (event === "up") {
				if (indexCargoPipeline === 1) {
					this.form.cargoPipelines[
						indexCargoPipeline
					].cargoPipelineEvents.forEach(
						(cargoPipelineEvent, indexCargoPipelineEvent) => {
							if (cargoPipelineEvent.partner.switcher) {
								this.form.cargoPipelines[
									indexCargoPipeline
								].cargoPipelineEvents[
									indexCargoPipelineEvent
								].partner.switcher = false;
								this.form.cargoPipelines[
									indexCargoPipeline
								].cargoPipelineEvents[
									indexCargoPipelineEvent
								].partner.value = null;
								this.form.cargoPipelines[
									indexCargoPipeline
								].cargoPipelineEvents[
									indexCargoPipelineEvent
								].partner.name = null;
								this.form.cargoPipelines[
									indexCargoPipeline
								].cargoPipelineEvents[
									indexCargoPipelineEvent
								].driver.value = null;
								this.form.cargoPipelines[
									indexCargoPipeline
								].cargoPipelineEvents[
									indexCargoPipelineEvent
								].vehicle.value = null;
								this.cargoPipelineCarRemote(
									"",
									indexCargoPipeline,
									indexCargoPipelineEvent
								);
								this.cargoPipelineDriverRemote(
									"",
									indexCargoPipeline,
									indexCargoPipelineEvent
								);
							}
						}
					);
				}
				this.form.cargoPipelines[
					indexCargoPipeline
				] = this.form.cargoPipelines.splice(
					indexCargoPipeline - 1,
					1,
					this.form.cargoPipelines[indexCargoPipeline]
				)[0];
			} else if (event === "down") {
				this.form.cargoPipelines[
					indexCargoPipeline
				] = this.form.cargoPipelines.splice(
					indexCargoPipeline + 1,
					1,
					this.form.cargoPipelines[indexCargoPipeline]
				)[0];
			} else if (event === "end") {
				this.form.cargoPipelines.push(
					this.form.cargoPipelines.splice(indexCargoPipeline, 1)[0]
				);
			}
		},
		changePlaceForEvent(
			event,
			indexCargoPipeline,
			indexCargoPipelineEvent
		) {
			if (
				this.form.cargoPipelines[indexCargoPipeline] &&
				this.form.cargoPipelines[indexCargoPipeline].cargoPipelineEvents
			) {
				if (event === "start") {
					this.form.cargoPipelines[
						indexCargoPipeline
					].cargoPipelineEvents.unshift(
						this.form.cargoPipelines[
							indexCargoPipeline
						].cargoPipelineEvents.splice(
							indexCargoPipelineEvent,
							1
						)[0]
					);
				} else if (event === "up") {
					this.form.cargoPipelines[
						indexCargoPipeline
					].cargoPipelineEvents[
						indexCargoPipelineEvent
					] = this.form.cargoPipelines[
						indexCargoPipeline
					].cargoPipelineEvents.splice(
						indexCargoPipelineEvent - 1,
						1,
						this.form.cargoPipelines[indexCargoPipeline]
							.cargoPipelineEvents[indexCargoPipelineEvent]
					)[0];
				} else if (event === "down") {
					this.form.cargoPipelines[
						indexCargoPipeline
					].cargoPipelineEvents[
						indexCargoPipelineEvent
					] = this.form.cargoPipelines[
						indexCargoPipeline
					].cargoPipelineEvents.splice(
						indexCargoPipelineEvent + 1,
						1,
						this.form.cargoPipelines[indexCargoPipeline]
							.cargoPipelineEvents[indexCargoPipelineEvent]
					)[0];
				} else if (event === "end") {
					this.form.cargoPipelines[
						indexCargoPipeline
					].cargoPipelineEvents.push(
						this.form.cargoPipelines[
							indexCargoPipeline
						].cargoPipelineEvents.splice(
							indexCargoPipelineEvent,
							1
						)[0]
					);
				}
			}
		},
    deleteComment() {
      this.form.comment.visible = false;
      this.form.comment.value = null;
	  this.updateButton = null;
    },
	destroyComment() {
		this.form.comment.visible = false;
      	this.form.comment.value = null;
	  	this.updateButton = null;
		this.showInfo("Комментарий удалён");
	},
		deletePlace(indexCargoPipeline) {
			this.form.cargoPipelines.splice(indexCargoPipeline, 1);
		},
		deletePlaceEvent(indexCargoPipeline, indexCargoPipelineEvent) {
			this.form.cargoPipelines[
				indexCargoPipeline
			].cargoPipelineEvents.splice(indexCargoPipelineEvent, 1);
			this.form.cargoPipelineEditVisible = false;
		},
		async getContractorsDriverByDriverId(contractorId, driverId) {
			this.loading = true;
			const response = await checkContractorsDriverByDriverId(
				contractorId,
				driverId
			);
			if (response && response.status === 200) {
				return response.data.id;
			}
			this.loading = false;
		},
		async getOrganizationsList(name) {
			this.loading = true;
			const params = { name: name, limit: 20 };
			const response = await organizationsList(params);
			if (response && response.status === 200) {
				this.form.organizations.options = response.data.items.map(
					(item) => {
						if (item.name === '"ООО ПЭК"') {
							this.data.defaultOrganization = item.id;
						}
						return { value: item.id, text: item.name };
					}
				);
			}
			this.form.organizations.value = this.data.defaultOrganization;
			this.loading = false;
		},
		async responsibleRemote(query) {
			this.loading = true;
			const response = await routeOwnersList({
				query: query,
				limit: 100,
			});
			if (response && response.status === 200) {
				this.form.responsible.options = response.data.items.map(
					(item) => {
						return {
							value: item.id,
							text: item.name,
						};
					}
				);
			}
			this.loading = false;
		},
		async onResponsibleChange(responsibleId) {
			this.loading = true;
			const response = await getCustomerRouteOwner(
				this.$store.state.user.ownerId,
				responsibleId
			);
			if (response && response.status === 200) {
				this.data.responsible = response.data;
			}
			this.loading = false;
		},
		async contractorRemote(query) {
			this.loading = true;
			const response = await customersContractorsList(
				this.$store.state.user.ownerId,
				{
					name: query,
					limit: 100,
				}
			);
			if (response && response.status === 200) {
				this.form.contractor.options = response.data.items.map(
					(item) => {
						return {
							value: item.contractor.id,
							text: item.contractor.name,
						};
					}
				);
			}
			this.loading = false;
		},
		async onContractorChange(value) {
			if (value) {
				this.loading = true;
				const response = await customersContractorRead(
					this.$store.state.user.ownerId,
					value
				);
				if (response && response.status === 200) {
					this.data.contractor = response.data;
				}
				this.loading = false;
			}
		},
		async driverOneRemote(query) {
			this.loading = true;
			const response = await contractorsDriverList(
				this.form.contractor.value,
				{
					fio: query,
					limit: 100,
					statuses: [1, 3],
				}
			);
			if (response && response.status === 200) {
				this.form.driverOne.options = response.data.items.map(
					(item) => {
						let disabled = !(
							item.activeAttorney && item.driver.status === 1
						);
						if (!disabled && this.form.cargoFlow) {
							disabled = !item.driver.driverAccessCargoFlow;
						}
						if (
							!disabled &&
							this.route.typeGuid ===
								"6193de5b-405a-426f-809a-4103f9e264db"
						) {
							disabled = !item.driver.driverAccessAuto;
						}
						if (
							!disabled &&
							this.route.typeGuid ===
								"8229411b-2c24-4dd1-af28-d7cfdbe62ef0"
						) {
							disabled = !item.driver.driverAccessContainer;
						}
						let fio = item.driver.surname + " " + item.driver.name;
						if (item.driver.patronymic) {
							fio = fio + " " + item.driver.patronymic;
						}
						return {
							value: item.id,
							text: fio,
							disabled: disabled,
						};
					}
				);
			}
			this.loading = false;
		},
		focusedCargoPipelineCarSelect(
			value,
			indexCargoPipeline,
			indexCargoPipelineEvent
		) {
			// Данный метод необходим для инициализации списка водителей и автомобилей, а также вычислении focusedCargoPipelineCarSelect
			// для определения в какие конкретные поля загружать список автомобилей.
			this.data.focusedCargoPipelineCarSelect = [
				indexCargoPipeline,
				indexCargoPipelineEvent,
			];
			this.onCargoPipelinePartnerChange(
				value,
				indexCargoPipeline,
				indexCargoPipelineEvent
			);
		},
		focusedCargoPipelineDriverSelect(
			value,
			indexCargoPipeline,
			indexCargoPipelineEvent
		) {
			// Данный метод необходим для инициализации списка водителей и автомобилей, а также вычислении focusedCargoPipelineDriverSelect
			// для определения в какие конкретные поля загружать список водителей.
			this.data.focusedCargoPipelineDriverSelect = [
				indexCargoPipeline,
				indexCargoPipelineEvent,
			];
			this.onCargoPipelinePartnerChange(
				value,
				indexCargoPipeline,
				indexCargoPipelineEvent
			);
		},
		async cargoPipelinePartnersDriverRemote(
			query,
			indexCargoPipeline,
			indexCargoPipelineEvent,
			partnerId
		) {
			if (typeof indexCargoPipeline !== "number") {
				indexCargoPipeline = this.data
					.focusedCargoPipelineDriverSelect[0];
			}
			if (typeof indexCargoPipelineEvent !== "number") {
				indexCargoPipelineEvent = this.data
					.focusedCargoPipelineDriverSelect[1];
			}
			const response = await contractorsPartnersDriversList(
				this.form.contractor.value,
				partnerId,
				{
					fio: query,
					limit: 100,
					statuses: [1, 3],
				}
			);
			if (response && response.status === 200) {
				this.form.cargoPipelines[
					indexCargoPipeline
				].cargoPipelineEvents[
					indexCargoPipelineEvent
				].driver.options = response.data.items.map((item) => {
					let disabled = !(
						item.activeAttorney && item.driver.status === 1
					);
					if (!disabled && this.form.cargoFlow) {
						disabled = !item.driver.driverAccessCargoFlow;
					}
					if (
						!disabled &&
						this.route.typeGuid ===
							"6193de5b-405a-426f-809a-4103f9e264db"
					) {
						disabled = !item.driver.driverAccessAuto;
					}
					if (
						!disabled &&
						this.route.typeGuid ===
							"8229411b-2c24-4dd1-af28-d7cfdbe62ef0"
					) {
						disabled = !item.driver.driverAccessContainer;
					}
					let fio = item.driver.surname + " " + item.driver.name;
					if (item.driver.patronymic) {
						fio = fio + " " + item.driver.patronymic;
					}
					return {
						value: item.id,
						text: fio,
						disabled: disabled,
					};
				});
			}
			this.loading = false;
		},
		async onDriverOneChange(value) {
			if (value) {
				this.loading = true;
				if (value !== this.form.driverTwo.value) {
					const response = await contractorsDriverRead(
						this.form.contractor.value,
						value
					);
					if (response && response.status === 200) {
						const driver = {
							attorneys: [],
							driverPhones: [],
						};
						let fio =
							response.data.driver.surname +
							" " +
							response.data.driver.name;
						if (response.data.driver.patronymic) {
							fio = fio + " " + response.data.driver.patronymic;
						}
						driver.id = response.data.driver.id;
						driver.activeAttorney = response.data.activeAttorney;
						driver.driverAccessAuto =
							response.data.driver.driverAccessAuto;
						driver.driverAccessCargoFlow =
							response.data.driver.driverAccessCargoFlow;
						driver.driverAccessContainer =
							response.data.driver.driverAccessContainer;
						driver.status = response.data.driver.status;
						driver.fio = fio;
						driver.inn = response.data.driver.inn;
						if (
							response.data.attorneys &&
							response.data.attorneys.length
						) {
							driver.attorneys = response.data.attorneys.map(
								(item) => {
									const attorney = {};
									attorney.id = item.id;
									attorney.number = item.number;
									attorney.status = item.status;
									attorney.dateEnd = moment(
										item.dateEnd
									).format("DD.MM.YYYY");
									attorney.dateStart = moment(
										item.dateStart
									).format("DD.MM.YYYY");
									if (item.file) {
										attorney.file = item.file;
									}
									return attorney;
								}
							);
						}
						if (response.data.driverPhones.length) {
							driver.driverPhones = response.data.driverPhones.map(
								(item) => {
									const phone = {};
									phone.name = item.name;
									phone.number =
										"+" +
										item.countryCode.code +
										item.areaCode +
										item.localNumber;
									if (item.additionalNumber) {
										phone.number =
											phone.number +
											"(" +
											item.additionalNumber +
											")";
									}
									return phone;
								}
							);
						}
						this.data.driverOne = driver;
					}
				} else {
					this.form.driverOne.value = "";
					this.showError("Вы уже добавили этого водителя");
				}
				this.loading = false;
			}
		},
		async driverTwoRemote(query) {
			this.loading = true;
			const response = await contractorsDriverList(
				this.form.contractor.value,
				{
					fio: query,
					limit: 100,
					statuses: [1, 3],
				}
			);
			if (response && response.status === 200) {
				this.form.driverTwo.options = response.data.items.map(
					(item) => {
						let disabled = !(
							item.activeAttorney && item.driver.status === 1
						);
						if (!disabled && this.form.cargoFlow) {
							disabled = !item.driver.driverAccessCargoFlow;
						}
						if (
							!disabled &&
							this.route.typeGuid ===
								"6193de5b-405a-426f-809a-4103f9e264db"
						) {
							disabled = !item.driver.driverAccessAuto;
						}
						if (
							!disabled &&
							this.route.typeGuid ===
								"8229411b-2c24-4dd1-af28-d7cfdbe62ef0"
						) {
							disabled = !item.driver.driverAccessContainer;
						}
						let fio = item.driver.surname + " " + item.driver.name;
						if (item.driver.patronymic) {
							fio = fio + " " + item.driver.patronymic;
						}
						return {
							value: item.id,
							text: fio,
							disabled: disabled,
						};
					}
				);
			}
			this.loading = false;
		},
		async onDriverTwoChange(value) {
			if (value) {
				this.loading = true;
				if (value !== this.form.driverOne.value) {
					const response = await contractorsDriverRead(
						this.form.contractor.value,
						value
					);
					if (response && response.status === 200) {
						const driver = {
							attorneys: [],
							driverPhones: [],
						};
						let fio =
							response.data.driver.surname +
							" " +
							response.data.driver.name;
						if (response.data.driver.patronymic) {
							fio = fio + " " + response.data.driver.patronymic;
						}
						driver.id = response.data.driver.id;
						driver.activeAttorney = response.data.activeAttorney;
						driver.driverAccessAuto =
							response.data.driver.driverAccessAuto;
						driver.driverAccessCargoFlow =
							response.data.driver.driverAccessCargoFlow;
						driver.driverAccessContainer =
							response.data.driver.driverAccessContainer;
						driver.status = response.data.driver.status;
						driver.fio = fio;
						driver.inn = response.data.driver.inn;
						if (
							response.data.attorneys &&
							response.data.attorneys.length
						) {
							driver.attorneys = response.data.attorneys.map(
								(item) => {
									const attorney = {};
									attorney.id = item.id;
									attorney.number = item.number;
									attorney.status = item.status;
									attorney.dateEnd = moment(
										item.dateEnd
									).format("DD.MM.YYYY");
									attorney.dateStart = moment(
										item.dateStart
									).format("DD.MM.YYYY");
									if (item.file) {
										attorney.file = item.file;
									}
									return attorney;
								}
							);
						}
						if (response.data.driverPhones.length) {
							driver.driverPhones = response.data.driverPhones.map(
								(item) => {
									const phone = {};
									phone.name = item.name;
									phone.number =
										"+" +
										item.countryCode.code +
										item.areaCode +
										item.localNumber;
									if (item.additionalNumber) {
										phone.number =
											phone.number +
											"(" +
											item.additionalNumber +
											")";
									}
									return phone;
								}
							);
						}
						this.data.driverTwo = driver;
					}
				} else {
					this.form.driverTwo.value = "";
					this.showError("Вы уже добавили этого водителя");
				}
				this.loading = false;
			}
		},
		async cargoPipelineDriverRemote(
			query,
			indexCargoPipeline,
			indexCargoPipelineEvent,
			contractorId
		) {
			this.form.cargoPipelines[indexCargoPipeline].cargoPipelineEvents[
				indexCargoPipelineEvent
			].driver.options = [];
			if (!contractorId) {
				contractorId = this.form.contractor.value;
			}
			if (typeof indexCargoPipeline !== "number") {
				indexCargoPipeline = this.data
					.focusedCargoPipelineDriverSelect[0];
			}
			if (typeof indexCargoPipelineEvent !== "number") {
				indexCargoPipelineEvent = this.data
					.focusedCargoPipelineDriverSelect[1];
			}
			const response = await contractorsDriverList(contractorId, {
				fio: query,
				limit: 100,
				statuses: [1, 3],
			});
			if (response && response.status === 200) {
				this.form.cargoPipelines[
					indexCargoPipeline
				].cargoPipelineEvents[
					indexCargoPipelineEvent
				].driver.options = response.data.items.map((item) => {
					let disabled = !(
						item.activeAttorney && item.driver.status === 1
					);
					if (!disabled && this.form.cargoFlow) {
						disabled = !item.driver.driverAccessCargoFlow;
					}
					if (
						!disabled &&
						this.route.typeGuid ===
							"6193de5b-405a-426f-809a-4103f9e264db"
					) {
						disabled = !item.driver.driverAccessAuto;
					}
					if (
						!disabled &&
						this.route.typeGuid ===
							"8229411b-2c24-4dd1-af28-d7cfdbe62ef0"
					) {
						disabled = !item.driver.driverAccessContainer;
					}
					let fio = item.driver.surname + " " + item.driver.name;
					if (item.driver.patronymic) {
						fio = fio + " " + item.driver.patronymic;
					}
					return {
						value: item.id,
						text: fio,
						disabled: disabled,
					};
				});
			}
			this.loading = false;
		},
		async onCargoPipelineDriverChange(
			value,
			indexCargoPipeline,
			indexCargoPipelineEvent
		) {
			this.loading = true;
			let contractorId = this.form.contractor.value;
			if (
				this.form.cargoPipelines[indexCargoPipeline]
					.cargoPipelineEvents[indexCargoPipelineEvent].partner
					.switcher &&
				this.form.cargoPipelines[indexCargoPipeline]
					.cargoPipelineEvents[indexCargoPipelineEvent].partner.value
			) {
				contractorId = this.form.cargoPipelines[indexCargoPipeline]
					.cargoPipelineEvents[indexCargoPipelineEvent].partner.value;
			}
			const response = await contractorsDriverRead(contractorId, value);
			if (response && response.status === 200) {
				const driver = {
					attorneys: [],
					driverPhones: [],
				};
				let fio =
					response.data.driver.surname +
					" " +
					response.data.driver.name;
				if (response.data.driver.patronymic) {
					fio = fio + " " + response.data.driver.patronymic;
				}
				driver.id = response.data.driver.id;
				driver.activeAttorney = response.data.activeAttorney;
				driver.driverAccessAuto = response.data.driver.driverAccessAuto;
				driver.driverAccessCargoFlow =
					response.data.driver.driverAccessCargoFlow;
				driver.driverAccessContainer =
					response.data.driver.driverAccessContainer;
				driver.status = response.data.driver.status;
				driver.fio = fio;
				driver.inn = response.data.driver.inn;
				if (response.data.attorneys && response.data.attorneys.length) {
					driver.attorneys = response.data.attorneys.map((item) => {
						const attorney = {};
						attorney.id = item.id;
						attorney.number = item.number;
						attorney.status = item.status;
						attorney.dateEnd = moment(item.dateEnd).format(
							"DD.MM.YYYY"
						);
						attorney.dateStart = moment(item.dateStart).format(
							"DD.MM.YYYY"
						);
						if (item.file) {
							attorney.file = item.file;
						}
						return attorney;
					});
				}
				if (response.data.driverPhones.length) {
					driver.driverPhones = response.data.driverPhones.map(
						(item) => {
							const phone = {};
							phone.name = item.name;
							phone.number =
								"+" +
								item.countryCode.code +
								item.areaCode +
								item.localNumber;
							if (item.additionalNumber) {
								phone.number =
									phone.number +
									"(" +
									item.additionalNumber +
									")";
							}
							return phone;
						}
					);
				}
				this.form.cargoPipelines[
					indexCargoPipeline
				].cargoPipelineEvents[
					indexCargoPipelineEvent
				].driver.data = driver;
			}
			this.loading = false;
		},
		async cargoPipelinePartnerRemote(
			query,
			indexCargoPipeline,
			indexCargoPipelineEvent
		) {
			if (typeof indexCargoPipeline !== "number") {
				indexCargoPipeline = this.data
					.focusedCargoPipelinePartnerSelect[0];
			}
			if (typeof indexCargoPipelineEvent !== "number") {
				indexCargoPipelineEvent = this.data
					.focusedCargoPipelinePartnerSelect[1];
			}
			const response = await contractorsPartnerList(
				this.form.contractor.value,
				{
					name: query,
					limit: 100,
				}
			);
			if (response && response.status === 200) {
				this.form.cargoPipelines[
					indexCargoPipeline
				].cargoPipelineEvents[
					indexCargoPipelineEvent
				].partner.options = response.data.items.map((item) => {
					return {
						value: item.contractor.id,
						text: item.contractor.name,
					};
				});
			}
			this.loading = false;
		},
		async cargoPipelinePartnersCarRemote(
			query,
			indexCargoPipeline,
			indexCargoPipelineEvent,
			partnerId
		) {
			if (typeof indexCargoPipeline !== "number") {
				indexCargoPipeline = this.data.focusedCargoPipelineCarSelect[0];
			}
			if (typeof indexCargoPipeline !== "number") {
				indexCargoPipelineEvent = this.data
					.focusedCargoPipelineCarSelect[1];
			}
			this.loading = true;
			const response = await contractorsPartnersTransportVehiclesList(
				this.form.contractor.value,
				partnerId,
				{
					brand_or_grn: query,
					limit: 100,
					is_truck: true,
				}
			);
			if (response && response.status === 200) {
				this.form.cargoPipelines[
					indexCargoPipeline
				].cargoPipelineEvents[
					indexCargoPipelineEvent
				].vehicle.options = response.data.items.map((item) => {
					return {
						value: item.id,
						text:
							item.vehicle.model.name +
							" " +
							item.vehicle.registrationNumber,
						errored: item.vehicle.blockedSecurity,
					};
				});
			}
			this.loading = false;
		},
		cargoPipelinePartnerClear(indexCargoPipeline, indexCargoPipelineEvent) {
			this.loading = true;
			this.form.cargoPipelines[indexCargoPipeline].cargoPipelineEvents[
				indexCargoPipelineEvent
			].driver.value = null;
			this.form.cargoPipelines[indexCargoPipeline].cargoPipelineEvents[
				indexCargoPipelineEvent
			].vehicle.value = null;
			this.cargoPipelineCarRemote(
				"",
				indexCargoPipeline,
				indexCargoPipelineEvent
			);
			this.cargoPipelineDriverRemote(
				"",
				indexCargoPipeline,
				indexCargoPipelineEvent
			);
			this.loading = false;
		},
		onCargoPipelinePartnerChange(
			partnerId,
			indexCargoPipeline,
			indexCargoPipelineEvent,
			switcherIsClicked
		) {
			this.loading = true;
			if (switcherIsClicked) {
				this.form.cargoPipelines[
					indexCargoPipeline
				].cargoPipelineEvents[
					indexCargoPipelineEvent
				].partner.value = null;
				this.form.cargoPipelines[
					indexCargoPipeline
				].cargoPipelineEvents[
					indexCargoPipelineEvent
				].partner.name = null;
				this.form.cargoPipelines[
					indexCargoPipeline
				].cargoPipelineEvents[
					indexCargoPipelineEvent
				].driver.value = null;
				this.form.cargoPipelines[
					indexCargoPipeline
				].cargoPipelineEvents[
					indexCargoPipelineEvent
				].vehicle.value = null;
				this.cargoPipelineCarRemote(
					"",
					indexCargoPipeline,
					indexCargoPipelineEvent
				);
				this.cargoPipelineDriverRemote(
					"",
					indexCargoPipeline,
					indexCargoPipelineEvent
				);
			} else if (partnerId) {
				this.form.cargoPipelines[
					indexCargoPipeline
				].cargoPipelineEvents[
					indexCargoPipelineEvent
				].partner.name = this.form.cargoPipelines[
					indexCargoPipeline
				].cargoPipelineEvents[
					indexCargoPipelineEvent
				].partner.options.filter(
					(item) =>
						item.value ===
						this.form.cargoPipelines[indexCargoPipeline]
							.cargoPipelineEvents[indexCargoPipelineEvent]
							.partner.value
				)[0].text;
				this.cargoPipelinePartnersCarRemote(
					"",
					indexCargoPipeline,
					indexCargoPipelineEvent,
					partnerId
				);
				this.cargoPipelinePartnersDriverRemote(
					"",
					indexCargoPipeline,
					indexCargoPipelineEvent,
					partnerId
				);
			} else {
				// this.form.cargoPipelines[indexCargoPipeline].cargoPipelineEvents[indexCargoPipelineEvent].driver.value = null;
				// this.form.cargoPipelines[indexCargoPipeline].cargoPipelineEvents[indexCargoPipelineEvent].vehicle.value = null;
				this.cargoPipelineCarRemote(
					"",
					indexCargoPipeline,
					indexCargoPipelineEvent
				);
				this.cargoPipelineDriverRemote(
					"",
					indexCargoPipeline,
					indexCargoPipelineEvent
				);
			}
			this.loading = false;
		},
		async carRemote(query) {
			this.loading = true;
			const response = await contractorsTransportVehiclesList(
				this.form.contractor.value,
				{
					brand_or_grn: query,
					limit: 100,
					is_truck: true,
				}
			);
			if (response && response.status === 200) {
				this.form.car.options = response.data.items.map((item) => {
					return {
						value: item.id,
						text:
							item.vehicle.model.brand +
							" " +
							item.vehicle.registrationNumber,
						errored: item.vehicle.blockedSecurity,
					};
				});
			}
			this.loading = false;
		},
		async onCarChange(value) {
			if (value) {
				this.loading = true;
				const response = await contractorsTransportVehicleRead(
					this.form.contractor.value,
					value
				);
				if (response && response.status === 200) {
					this.data.car.id = response.data.vehicle.id;
					this.data.car.blockedSecurity =
						response.data.vehicle.blockedSecurity;
					this.data.car.registrationNumber =
						response.data.vehicle.registrationNumber;
					this.data.car.name = response.data.vehicle.model.name;
					this.data.car.containerTransportVehicle =
						response.data.vehicle.model.containerTransportVehicle;
					this.data.car.loadCapacity =
						response.data.vehicle.model.loadCapacity;
					this.data.car.bodyHeight =
						response.data.vehicle.model.bodyHeight;
					this.data.car.bodyWidth =
						response.data.vehicle.model.bodyWidth;
					this.data.car.bodyDepth =
						response.data.vehicle.model.bodyDepth;
					this.data.car.bodyVolume =
						response.data.vehicle.model.bodyVolume;
					this.data.car.type = response.data.vehicle.model.type.name;
					if (response.data.vehicle.bodyType) {
						this.data.car.bodyType =
							response.data.vehicle.bodyType.name;
					}
				}
				this.loading = false;
			}
		},
		async cargoPipelineCarRemote(
			query,
			indexCargoPipeline,
			indexCargoPipelineEvent,
			contractorId
		) {
			this.form.cargoPipelines[indexCargoPipeline].cargoPipelineEvents[
				indexCargoPipelineEvent
			].vehicle.options = [];
			if (!contractorId) {
				contractorId = this.form.contractor.value;
			}
			if (typeof indexCargoPipeline !== "number") {
				indexCargoPipeline = this.data.focusedCargoPipelineCarSelect[0];
			}
			if (typeof indexCargoPipeline !== "number") {
				indexCargoPipelineEvent = this.data
					.focusedCargoPipelineCarSelect[1];
			}
			this.loading = true;
			const response = await contractorsTransportVehiclesList(
				contractorId,
				{
					brand_or_grn: query,
					limit: 100,
					is_truck: true,
				}
			);
			if (response && response.status === 200) {
				this.form.cargoPipelines[
					indexCargoPipeline
				].cargoPipelineEvents[
					indexCargoPipelineEvent
				].vehicle.options = response.data.items.map((item) => {
					return {
						value: item.id,
						text:
							item.vehicle.model.brand +
							" " +
							item.vehicle.registrationNumber,
						errored: item.vehicle.blockedSecurity,
					};
				});
			}
			this.loading = false;
		},
		async onCargoPipelineCarChange(
			value,
			indexCargoPipeline,
			indexCargoPipelineEvent
		) {
			if (value) {
				this.loading = true;
				let contractorId = this.form.contractor.value;
				if (
					this.form.cargoPipelines[indexCargoPipeline]
						.cargoPipelineEvents[indexCargoPipelineEvent].partner
						.switcher &&
					this.form.cargoPipelines[indexCargoPipeline]
						.cargoPipelineEvents[indexCargoPipelineEvent].partner
						.value
				) {
					contractorId = this.form.cargoPipelines[indexCargoPipeline]
						.cargoPipelineEvents[indexCargoPipelineEvent].partner
						.value;
				}
				const response = await contractorsTransportVehicleRead(
					contractorId,
					value
				);
				if (response && response.status === 200) {
					const vehicle = {};
					vehicle.id = response.data.vehicle.id;
					vehicle.blockedSecurity =
						response.data.vehicle.blockedSecurity;
					vehicle.registrationNumber =
						response.data.vehicle.registrationNumber;
					vehicle.name = response.data.vehicle.model.name;
					vehicle.containerTransportVehicle =
						response.data.vehicle.model.containerTransportVehicle;
					vehicle.loadCapacity =
						response.data.vehicle.model.loadCapacity;
					vehicle.bodyHeight = response.data.vehicle.model.bodyHeight;
					vehicle.bodyWidth = response.data.vehicle.model.bodyWidth;
					vehicle.bodyDepth = response.data.vehicle.model.bodyDepth;
					vehicle.bodyVolume = response.data.vehicle.model.bodyVolume;
					vehicle.type = response.data.vehicle.model.type.name;
					if (response.data.vehicle.bodyType) {
						vehicle.bodyType = response.data.vehicle.bodyType.name;
					}
					this.form.cargoPipelines[
						indexCargoPipeline
					].cargoPipelineEvents[
						indexCargoPipelineEvent
					].vehicle.data = vehicle;
				}
				this.loading = false;
			}
		},
		async carContainerRemote(query) {
			this.loading = true;
			const response = await contractorsContainersList(
				this.form.contractor.value,
				{
					number: query,
					limit: 100,
				}
			);
			if (response && response.status === 200) {
				this.form.carContainer.options = response.data.items.map(
					(item) => {
						return {
							value: item.id,
							text: item.number,
						};
					}
				);
			}
			this.loading = false;
		},
		async getCarContractorsContainerData(contractorId, containerId) {
			this.loading = true;
			const response = await contractorsContainerRead(
				contractorId,
				containerId
			);
			if (response && response.status === 200) {
				const containerData = {};
				containerData.number = response.data.number;
				containerData.name = response.data.name;
				containerData.height = response.data.height;
				containerData.width = response.data.width;
				containerData.depth = response.data.depth;
				containerData.maximumPayload = response.data.maximumPayload;
				containerData.volume = response.data.volume;
				this.data.carContainer.push(containerData);
			}
			this.loading = false;
		},
		onCarContainerChange(value) {
			if (value.length) {
				this.data.carContainer = [];
				value.forEach((container) => {
					this.getCarContractorsContainerData(
						this.form.contractor.value,
						container
					);
				});
			}
		},
		async trailerRemote(query) {
			this.loading = true;
			const response = await contractorsTransportVehiclesList(
				this.form.contractor.value,
				{
					brand_or_grn: query,
					limit: 100,
					is_truck: false,
				}
			);
			if (response && response.status === 200) {
				this.form.trailer.options = response.data.items.map((item) => {
					return {
						value: item.id,
						text:
							item.vehicle.model.name +
							" " +
							item.vehicle.registrationNumber,
						errored: item.vehicle.blockedSecurity,
					};
				});
			}
			this.loading = false;
		},
		async onTrailerChange(value) {
			if (value) {
				this.loading = true;
				const response = await contractorsTransportVehicleRead(
					this.form.contractor.value,
					value
				);
				if (response && response.status === 200) {
					this.data.trailer.id = response.data.vehicle.id;
					this.data.trailer.blockedSecurity =
						response.data.vehicle.blockedSecurity;
					this.data.trailer.registrationNumber =
						response.data.vehicle.registrationNumber;
					this.data.trailer.name = response.data.vehicle.model.name;
					this.data.trailer.containerTransportVehicle =
						response.data.vehicle.model.containerTransportVehicle;
					this.data.trailer.loadCapacity =
						response.data.vehicle.model.loadCapacity;
					this.data.trailer.bodyHeight =
						response.data.vehicle.model.bodyHeight;
					this.data.trailer.bodyWidth =
						response.data.vehicle.model.bodyWidth;
					this.data.trailer.bodyDepth =
						response.data.vehicle.model.bodyDepth;
					this.data.trailer.bodyVolume =
						response.data.vehicle.model.bodyVolume;
					this.data.trailer.type =
						response.data.vehicle.model.type.name;
					if (response.data.vehicle.bodyType) {
						this.data.trailer.bodyType =
							response.data.vehicle.bodyType.name;
					}
				}
				this.loading = false;
			}
		},
		async trailerContainerRemote(query) {
			this.loading = true;
			const response = await contractorsContainersList(
				this.form.contractor.value,
				{
					number: query,
					limit: 100,
				}
			);
			if (response && response.status === 200) {
				this.form.trailerContainer.options = response.data.items.map(
					(item) => {
						return {
							value: item.id,
							text: item.number,
						};
					}
				);
			}
			this.loading = false;
		},
		async getTrailerContractorsContainerData(contractorId, containerId) {
			this.loading = true;
			const response = await contractorsContainerRead(
				contractorId,
				containerId
			);
			if (response && response.status === 200) {
				const containerData = {};
				containerData.number = response.data.number;
				containerData.name = response.data.name;
				containerData.height = response.data.height;
				containerData.width = response.data.width;
				containerData.depth = response.data.depth;
				containerData.maximumPayload = response.data.maximumPayload;
				containerData.volume = response.data.volume;
				this.data.trailerContainer.push(containerData);
			}
			this.loading = false;
		},
		onTrailerContainerChange(value) {
			if (value.length) {
				this.data.trailerContainer = [];
				value.forEach((container) => {
					this.getTrailerContractorsContainerData(
						this.form.contractor.value,
						container
					);
				});
			}
		},
		async cargoPipelinePlacesEventRemote(query) {
			this.loading = true;
			const response = await cargoPipelinePlacesEvent({
				query: query,
				limit: 100,
			});
			if (response && response.status === 200) {
				this.form.cargoPipelinePlacesEvent.options = response.data.items.map(
					(item) => {
						return {
							value: item.id,
							text: item.name,
						};
					}
				);
			}
			this.loading = false;
		},
		async cargoPipelineEventRemote(query) {
			this.loading = true;
			const response = await cargoPipelineEvent({
				query: query,
				limit: 100,
			});
			if (response && response.status === 200) {
				this.data.cargoPipelineEventOptions = response.data.items.map(
					(item) => {
						return {
							value: item.id,
							text: item.name,
						};
					}
				);
			}
			this.loading = false;
		},
		async onSubmit() {
			if (this.validate()) {
				this.loading = true;
				const response = await customersRouteCreate(
					this.$store.state.user.ownerId,
					this.formStr
				);
				if (response && response.status === 201) {
					this.showSuccess("Рейс создан");
					this.$router.push({
						path: `/customers/id${this.$store.state.user.ownerId}/flight/id${response.data.id}`,
					});
				}
				this.loading = false;
			}
		},
		async customersRouteAuctionCreate() {
			if (this.validate()) {
				this.loading = true;
				const response = await customersRouteAuctionCreate(
					this.$store.state.user.ownerId,
					this.formStr
				);
				if (response && response.status === 201) {
					this.showSuccess("Рейс создан переведен в аукцион");
					this.$router.push({
						path: `/auctions/customer/auction/id${response.data.id}#auctionTermsAndParticipants`,
					});
				}
				this.loading = false;
			}
		},
		chkState(val) {
			// const field = this.$v.form[val];
			const field = this.form[val];
			if (!field.$dirty) {
				return null;
			} else if (field.$invalid) {
				return false;
			} else {
				return true;
			}
		},
		findFirstError(component = this) {
			if (component.state === false) {
				if (component.$refs.input) {
					component.$refs.input.focus();
					return true;
				}
				if (component.$refs.check) {
					component.$refs.check.focus();
					return true;
				}
			}
			let focused = false;
			component.$children.some((child) => {
				focused = this.findFirstError(child);
				return focused;
			});

			return focused;
		},
		validate() {
			this.$v.$touch();
			this.$nextTick(() => this.findFirstError());
			return this.isValid;
		},
		formatDate(date) {
			return moment(date).format("DD.MM.YYYY");
		},
		numberRUFormat(value) {
			return numberToRUFormat(value);
		},
	},
};
</script>

<style lang="scss">
.VueTables__table {
	margin-bottom: 2rem;
}
</style>
